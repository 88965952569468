/*!

=========================================================
* Argon Dashboard React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import BackupLog from "views/BackupLog.js";
import BackupSetup from "views/BackupSetup.js";
import Superadmin from "views/Superadmin.js";
import PreviousRecordings from "views/PreviousRecordings";

var routes = [
  {
    path: "/settings",
    name: "Backup Setup",
    icon: "ni ni-settings-gear-65 text-secondary",
    component: BackupSetup,
    layout: "/admin",
  },
  {
    path: "/previous-recordings",
    name: "Previous Recordings",
    icon: "ni ni-button-play text-secondary",
    component: PreviousRecordings,
    layout: "/admin",
  },
  {
    path: "/log",
    name: "Backup Log",
    icon: "ni ni-bullet-list-67 text-secondary",
    component: BackupLog,
    layout: "/admin",
  },
  {
    path: "/superadmin",
    name: "Superadmin",
    icon: "ni ni-settings text-secondary",
    component: Superadmin,
    role: "Superadmin",
    layout: "/admin",
  },
];
export default routes;
