import { combineReducers } from "redux";
import accountReducer from "./accountReducer";
import authReducer from "./authReducer";
import serviceReducer from "./serviceReducer";
import superadminReducer from "./superadminReducer";
import logReducer from "./logReducer";
import prevRecordingReducer from "./prevRecordingReducer";

export default combineReducers({
  auth: authReducer,
  service: serviceReducer,
  super: superadminReducer,
  account: accountReducer,
  log: logReducer,
  prevRecordings: prevRecordingReducer,
});
