import React from "react";
import { useDispatch, useSelector } from "react-redux";
// reactstrap components
import {
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  DropdownToggle,
  Navbar,
  Nav,
  Container,
} from "reactstrap";

import { logoutUser } from "store/actions/authActions";
import { toggleAccount } from "store/actions/accountActions";

const AdminNavbar = (props) => {
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.auth);

  const logout = (e) => {
    e.preventDefault();
    dispatch(logoutUser());
  };

  const handleToggleAccount = (e) => {
    e.preventDefault();
    dispatch(toggleAccount());
  };

  return (
    <>
      <Navbar className="navbar-top navbar-dark" expand="md" id="navbar-main">
        <Container fluid>
          <h4 className="h4 mb-0 text-white text-uppercase d-none d-md-inline-block">
            {props.brandText}
          </h4>
          <Nav className="align-items-center d-none d-md-flex" navbar>
            <UncontrolledDropdown nav>
              <DropdownToggle className="pr-0" nav>
                <span className="mb-0 text-sm font-weight-bold">
                  {user.first_name ? user.first_name : ""}{" "}
                  <i className="ni ni-circle-08" />
                </span>
              </DropdownToggle>
              <DropdownMenu className="dropdown-menu-arrow" right>
                <DropdownItem className="noti-title" header tag="div">
                  <h6 className="text-overflow m-0">Welcome!</h6>
                </DropdownItem>
                <DropdownItem onClick={handleToggleAccount}>
                  <i className="ni ni-credit-card" />
                  <span>Account</span>
                </DropdownItem>
                <DropdownItem
                  href="https://zoom.us/recording"
                  rel="noopener noreferrer"
                  target="_blank"
                  className="cursor-normal"
                >
                  <i className="ni ni-bullet-list-67" />
                  <span>Zoom Recordings</span>
                </DropdownItem>
                <DropdownItem divider />
                <DropdownItem onClick={logout}>
                  <i className="ni ni-button-power" />
                  <span>Logout</span>
                </DropdownItem>
              </DropdownMenu>
            </UncontrolledDropdown>
          </Nav>
        </Container>
      </Navbar>
    </>
  );
};

export default AdminNavbar;
