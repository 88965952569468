import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";
import { Provider } from "react-redux";
import reduxThunk from "redux-thunk";
import { createStore, applyMiddleware } from "redux";
import axios from "axios";

import "assets/plugins/nucleo/css/nucleo.css";
import "@fortawesome/fontawesome-free/css/all.min.css";
import "assets/scss/argon-dashboard-react.scss";
import "assets/css/main.css"

import reducers from "store/reducers";
import AdminLayout from "layouts/Admin";
import AuthLayout from "layouts/Auth";
import PrivateRoute from "private-routes";
import SuperadminRoute from "superadmin-routes";
import { checkAuth } from "store/actions/authActions";

// Create Redux Store
const store = createStore(reducers, {}, applyMiddleware(reduxThunk));
axios.defaults.withCredentials = true;

store.dispatch(checkAuth());

ReactDOM.render(
  <Provider store={store}>
    <BrowserRouter>
      <Switch>
        <Route path="/auth" render={(props) => <AuthLayout {...props} />} />
        <SuperadminRoute
          path="/admin/superadmin"
          render={(props) => <AdminLayout {...props} />}
        />
        <PrivateRoute
          path="/admin"
          render={(props) => <AdminLayout {...props} />}
        />
        <Redirect from="/" to="/admin/derp" />
      </Switch>
    </BrowserRouter>
  </Provider>,
  document.getElementById("root")
);
