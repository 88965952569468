/* eslint-disable no-nested-ternary */
import React from "react";
import { Route, Redirect, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";

import Admin from "layouts/Admin";

const PrivateRoute = ({ component: Component, ...rest }) => {
  const currentLocation = useLocation();
  const { isAuthenticated } = useSelector((state) => state.auth);

  return (
    <Route
      {...rest}
      render={(props) =>
        isAuthenticated ? (
          <Admin {...props} />
        ) : (
          <Redirect
            to={{ pathname: "/auth", state: { redirect: currentLocation } }}
            {...props}
          />
        )
      }
    />
  );
};

export default PrivateRoute;
