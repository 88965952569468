import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import {
  Card,
  CardHeader,
  CardBody,
  Container,
  Row,
  Col,
  Spinner,
  Table,
  Alert,
  UncontrolledTooltip,
} from "reactstrap";
// core components
import Header from "components/Headers/Header.js";
import { useLocation } from "react-router-dom";
import NewWindow from "react-new-window";
import queryString from "query-string";

import oauth from "helpers/oauth";
import BackupItem from "components/BackupItem/BackupItem";
import ReviewAskModal from "components/Modals/ReviewAskModal";

import {
  addService,
  getServices,
  removeService,
  toggleActive,
  getVotes,
  setVote,
} from "store/actions/serviceActions";
import {
  toggleAccount,
  setSubscriberInfo,
  setDeleteAfterUpload,
} from "store/actions/accountActions";
import { getDeleteAfterUpload } from "store/actions/accountActions";

const BackupSetup = () => {
  const dispatch = useDispatch();
  let history = useHistory();
  const { services, votes, loading } = useSelector((state) => state.service);
  const { user } = useSelector((state) => state.auth);
  const { subscription } = useSelector((state) => state.account);
  const [pageLoading, setPageLoading] = useState(true);
  const [limitWarn, setlimitWarn] = useState(false);
  const [freeWarn, setFreeWarn] = useState(false);
  const [proWarn, setProWarn] = useState(false);
  const [duplicateServiceWarn, setDuplicateServiceWarn] = useState({
    bool: false,
    service: "",
  });
  const [reviewModal, setReviewModal] = useState(false);
  const [subscribeNotification, setSubscribeNotification] = useState(false);
  const [cancelNotification, setCancelNotification] = useState(false);

  const [paymentUpdateNotification, setPaymentUpdateNotification] = useState(
    false
  );

  const [serviceClose, setServiceClose] = useState(false);
  const [serviceWindow, setServiceWindow] = useState({
    open: false,
    url: "",
    name: "",
  });
  let location = useLocation();

  useEffect(() => {
    dispatch(getDeleteAfterUpload());
    dispatch(getVotes());
    services.length === 0 && dispatch(getServices());
    //eslint-disable-next-line
  }, []);

  useEffect(() => {
    if(subscription == 'Free' || subscription == 'Cancelled') {
      dispatch(toggleAccount());
    }
  }, [subscription])

  useEffect(() => {
    const parsed = queryString.parse(location.search);
    const service = location.pathname.substring(
      location.pathname.lastIndexOf("/") + 1
    );
    if (parsed.hostedpage_id) {
      dispatch(setSubscriberInfo(parsed.hostedpage_id));
      history.push("/admin/settings?subscribe=success");
    }
    if (parsed.subscribe === "success") {
      setSubscribeNotification(true);
    }
    if (parsed.cancel === "success") {
      setCancelNotification(true);
    }
    if (parsed.paymentupdate === "success") {
      setPaymentUpdateNotification(true);
    }
    if (service === "settings" || parsed.length === 0) {
      setPageLoading(false);
      return;
    } else if (parsed.tenant) {
      //send tenant to backend for admin auth (specific to onedrive)
      dispatch(addService({ service: service, tenant: parsed.tenant }));
      setPageLoading(false);
      setServiceClose(true);
      setTimeout(() => {
        window.close();
      }, 2000);
    } else if (parsed.code) {
      //send auth code to backend to create and save access and refresh tokens
      dispatch(addService({ service: service, code: parsed.code }));
      setPageLoading(false);
      setServiceClose(true);
      setTimeout(() => {
        window.close();
      }, 2000);
    } else {
      setPageLoading(false);
      window.close();
    }
    // eslint-disable-next-line
  }, [location]);

  const handleServiceClick = (service) => {
    if (loading) {
      return;
    }

    // User is not a prepaid user
    if (subscription === "Free" || subscription === "Cancelled") {
      // Check for if user is trying to access another service as a free user

      setFreeWarn(true);
      return;

      // if (
      //   services.length >= 1 ||
      //   (service !== "dropbox" && service !== "vimeo")
      // ) {
      //   setFreeWarn(true);
      //   return;
      // }
    }


    // Kinda redundant now
    // // User is a prepaid user
    // if (
    //   subscription !== "Free" &&
    //   subscription !== "Cancelled" &&
    //   services.length >= Infinity // Sets limit, initally was 3 
    // ) {
    //   setlimitWarn(true);
    //   return;
    // }

    if (
      services.filter(
        (svc) => svc.service.toUpperCase() === service.toUpperCase()
      ).length > 0
    ) {
      setDuplicateServiceWarn({
        bool: true,
        service: service.charAt(0).toUpperCase() + service.slice(1),
      });
    }
    setServiceWindow({ open: true, url: oauth[service] });
  };

  const windowUnmount = () => {
    setServiceWindow({ open: false, url: "", name: "" });
    setTimeout(() => {
      dispatch(getServices());
    }, 1500);
    setTimeout(() => {
      if (services.length === 0) {
        setReviewModal(true);
      }
    }, 3000);
  };

  const handleDeleteService = (id) => {
    dispatch(removeService({ id }));
  };

  const toggleTip = () => {
    setSubscribeNotification(false);
    setCancelNotification(false);
    setPaymentUpdateNotification(false);
    setDuplicateServiceWarn({
      bool: false,
      service: null,
    });
    history.push("/admin/settings");
  };

  const handleToggleAccount = (e) => {
    e.preventDefault();
    setFreeWarn(false);
    dispatch(toggleAccount());
  };

  const setDeleteOption = (e) => {
    if (subscription === "Free" || subscription === "Cancelled") {
      setProWarn(true);
      return;
    } else {
      dispatch(setDeleteAfterUpload({ value: e.target.checked }));
    }
  };

  const handleToggleService = (id, active, service) => {
    if (!active) {
      if (subscription === "Free" || subscription === "Cancelled") {
        if (services.filter((serv) => serv.active).length >= 1) {
          setFreeWarn(true);
        } else if (service !== "Dropbox" && service !== "Vimeo") {
          setFreeWarn(true);
        } else {
          dispatch(toggleActive({ id }));
        }
      } else {
        dispatch(toggleActive({ id }));
      }
    } else {
      dispatch(toggleActive({ id }));
    }
  };

  const handleVote = (service) => {
    if (!user.voted && !loading) {
      dispatch(setVote({ service: service }));
    }
    return;
  };

  return (
    <>
      {serviceWindow.open && (
        <NewWindow
          url={serviceWindow.url}
          name={serviceWindow.name}
          features={{
            toolbar: "no",
            menubar: "no",
            width: "650",
            height: "800",
          }}
          center="parent"
          onUnload={windowUnmount}
        />
      )}
      {reviewModal && (
        <ReviewAskModal
          isOpen={reviewModal}
          toggle={() => setReviewModal(false)}
        />
      )}
      <Header />
      <Container className="mt--8" fluid>
        {serviceClose ? (
          <Row className="mt-5">
            <Col className="mb-8" xl="12">
              <Card className="shadow py-5 text-center">
                <CardBody>
                  <div className="col">
                    <h2 className="mb-1">
                      This window should close automatically.
                    </h2>
                    <h3>
                      We'll take care of final setup in the original window.
                    </h3>
                    <h5>
                      If this window doesn't close automatically, feel free to
                      close it manually.
                    </h5>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        ) : pageLoading ? (
          <div className="text-center mt-7">
            <Spinner
              color="info"
              type="grow"
              style={{ width: "4rem", height: "4rem" }}
            />
          </div>
        ) : (
          <>
            <Row className="my-5">
              <Alert
                color="success"
                isOpen={subscribeNotification}
                className="fixed-top mt-lg-4 mt-6 ml-lg-9 mr-lg-4 mx-2 font-weight-bold"
                toggle={toggleTip}
              >
                Subscription successful! You can manage your subscription using
                the Account tab in the settings menu.
              </Alert>
              <Alert
                color="warning"
                isOpen={cancelNotification}
                className="fixed-top mt-lg-4 mt-6 ml-lg-9 mr-lg-4 mx-2 font-weight-bold"
                toggle={toggleTip}
              >
                Your account will be cancelled at the end of the billing term
                and you will not be charged going forward!
              </Alert>
              <Alert
                color="success"
                isOpen={paymentUpdateNotification}
                className="fixed-top mt-lg-4 mt-6 ml-lg-9 mr-lg-4 mx-2 font-weight-bold"
                toggle={toggleTip}
              >
                Payment method successfully updated!
              </Alert>
              <Alert
                color="warning"
                isOpen={duplicateServiceWarn.bool}
                className="fixed-top mt-lg-4 mt-6 ml-lg-9 mr-lg-4 mx-2 font-weight-bold"
                toggle={toggleTip}
              >
                Tip: to add more than one {duplicateServiceWarn.service} backup
                account, make sure you are logged out of your existing{" "}
                {duplicateServiceWarn.service} account in your browser first, to
                ensure you are prompted for login credentials for the new{" "}
                {duplicateServiceWarn.service} account.
              </Alert>
              <Alert
                color="danger"
                isOpen={limitWarn}
                className="fixed-top mt-lg-4 mt-6 ml-lg-9 mr-lg-4 mx-2 font-weight-bold"
                toggle={() => setlimitWarn(false)}
              >
                Your account is limited to 3 backup locations. To add a new
                backup location, delete an existing location first.
              </Alert>
              <Alert
                color="warning"
                isOpen={freeWarn}
                className="fixed-top mt-lg-4 mt-6 ml-lg-9 mr-lg-4 mx-2 font-weight-bold"
                toggle={() => setFreeWarn(false)}
              >
                Free accounts cannot use cloud storage backup,{" "}
                <strong
                  className="text-underline cursor-pointer"
                  onClick={handleToggleAccount}
                >
                  sign up for a paid plan.
                </strong>
              </Alert>
              <Alert
                color="warning"
                isOpen={proWarn}
                className="fixed-top mt-lg-4 mt-6 ml-lg-9 mr-lg-4 mx-2 font-weight-bold"
                toggle={() => setProWarn(false)}
              >
                This is a pro feature. To enable this feature,{" "}
                <strong
                  className="text-underline cursor-pointer"
                  onClick={handleToggleAccount}
                >
                  sign up for a paid plan.
                </strong>
              </Alert>
              <Col className="mb-8" xl="12">
                <Card className="shadow">
                  <CardHeader className="border-0 pb-0">
                    <Row className="align-items-center">
                      <div className="col">
                        <h3 className="mb-1">
                          Choose where to backup your Zoom recordings...
                        </h3>
                        <h5>
                          We'll automatically save all future Zoom recordings to
                          the backup and/or video hosting locations of your
                          choice.
                          <br />
                        </h5>
                      </div>
                    </Row>
                  </CardHeader>
                  <CardBody className="pb-0 pt-3">
                    <Row>
                      <Col xl={4} className="my-1">
                        <div
                          style={{ cursor: "pointer" }}
                          onClick={() => handleServiceClick("dropbox")}
                        >
                          <Card className="">
                            <CardBody className="text-center">
                              <img
                                style={{ height: "30px" }}
                                alt="..."
                                src={require("assets/img/services/dropbox.svg")}
                              />
                            </CardBody>
                          </Card>
                        </div>
                      </Col>
                      <Col xl={4} className="my-1">
                        <div
                          style={{ cursor: "pointer" }}
                          onClick={() => handleServiceClick("vimeo")}
                        >
                          <Card className="">
                            {/* <h5 className="ribbon-warning">SOON</h5> */}
                            <CardBody className="text-center">
                              <img
                                style={{ height: "30px" }}
                                alt="..."
                                src={require("assets/img/services/vimeo.svg")}
                              />
                            </CardBody>
                          </Card>
                        </div>
                      </Col>
                      <Col xl={4} className="my-1">
                        <div
                          style={{ cursor: "pointer" }}
                          onClick={() => handleServiceClick("onedrive")}
                        >
                          <Card className="">
                            <CardBody className="text-center d-inline-block">
                              {/* <h5 className="ribbon">PRO</h5> */}
                              <img
                                style={{ height: "30px" }}
                                alt="..."
                                src={require("assets/img/services/onedrive.svg")}
                              />
                              {/* <span className="text-primary"> Personal</span> */}
                            </CardBody>
                          </Card>
                        </div>
                      </Col>
                      <Col xl={4} className="my-1">
                        <div
                          style={{ cursor: "pointer" }}
                          onClick={() => handleServiceClick("drive")}
                        >
                          <Card className="">
                            <CardBody className="text-center d-inline-block">
                              {/* <h5 className="ribbon">PRO</h5> */}
                              <img
                                style={{ height: "30px" }}
                                alt="..."
                                src={require("assets/img/services/google.svg")}
                              />
                            </CardBody>
                          </Card>
                        </div>
                      </Col>
                      <Col xl={4} className="my-1">
                        <div
                          style={{ cursor: "pointer" }}
                          onClick={() => handleServiceClick("youtube")}
                        >
                          <Card className="">
                            <CardBody className="text-center d-inline-block">
                              {/* <h5 className="ribbon">PRO</h5> */}
                              <img
                                style={{ height: "30px" }}
                                alt="..."
                                src={require("assets/img/services/youtube.svg")}
                              />
                            </CardBody>
                          </Card>
                        </div>
                      </Col>
                    </Row>
                    <hr />
                  </CardBody>
                  <Row>
                    <Col xl="12" xs="12">
                      <h4 className="ml-3">Backup Settings</h4>
                      {loading ? (
                        <Spinner
                          size="sm"
                          className="ml-4 mt-2 mb-3"
                          color="primary"
                        />
                      ) : (
                        <div className="ml-6 custom-control custom-checkbox mt-2 mb-3">
                          <span
                            className="badge badge-primary-alt"
                            style={{ marginRight: "32px" }}
                          >
                            PRO
                          </span>
                          <div onClick={setDeleteOption}>
                            <input
                              className="custom-control-input mt-1"
                              id="delete_after_upload"
                              type="checkbox"
                              disabled={
                                subscription === "Free" ||
                                subscription === "Cancelled"
                              }
                              defaultChecked={user.delete_after_upload}
                              onChange={setDeleteOption}
                            />
                            <label
                              className="custom-control-label"
                              style={{ paddingTop: "2px" }}
                              htmlFor="delete_after_upload"
                            >
                              Delete recordings from Zoom after backup{" "}
                              <i
                                className="fas fa-info-circle mr-1"
                                id="UncontrolledTooltipExample"
                              />
                              <UncontrolledTooltip
                                placement="right"
                                target="UncontrolledTooltipExample"
                                autohide={false}
                                className="shadow"
                                style={{ fontWeight: 300, fontSize: "12.5px" }}
                              >
                                We will only delete the recording from Zoom if it is successfully backed up to all enabled destinations. Deleted Zoom recordings are accessible in Zoom's{" "}
                                <a
                                  href="https://zoom.us/recording/trash"
                                  rel="noopener noreferrer"
                                  target="_blank"
                                  className="text-white text-underline"
                                >
                                  trash folder
                                </a>{" "}
                                for 30 days. <br />
                                <br />
                                <i>
                                  Note: Zoom file deletion can be delayed up to
                                  30 minutes after successful backup.
                                </i>
                              </UncontrolledTooltip>
                            </label>
                          </div>
                        </div>
                      )}
                      <h4 className="ml-3">
                        Backup Locations
                        <span className="d-xl-none ml-3 text-xs text-muted">
                          (...scroll right for all options)
                        </span>
                      </h4>
                      <Table
                        className="align-items-center table-flush px--2"
                        responsive
                      >
                        <thead className="thead-light">
                          <tr>
                            <th scope="col">Active</th>
                            <th scope="col">Service</th>
                            <th scope="col">Backup Name</th>
                            <th scope="col">Options</th>
                            <th scope="col">Created</th>
                            <th scope="col">File Location</th>
                            <th scope="col" className="text-right">
                              Actions
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {loading ? (
                            <tr>
                              <th scope="row">
                                <Spinner size="md" color="info" />
                              </th>
                            </tr>
                          ) : services.length === 0 ? (
                            <tr>
                              <th scope="row">Add a backup service above...</th>
                            </tr>
                          ) : (
                            services.map((serv) => {
                              return (
                                <BackupItem
                                  key={serv._id}
                                  service={serv.service}
                                  name={serv.name}
                                  created={serv.createdAt}
                                  location={serv.location}
                                  removeService={handleDeleteService}
                                  toggleService={handleToggleService}
                                  id={serv._id}
                                  active={serv.active}
                                  options={serv.options}
                                  backupOptions={serv.backupOptions}
                                />
                              );
                            })
                          )}
                        </tbody>
                      </Table>
                    </Col>
                  </Row>
                </Card>
                <Card className="mt-7">
                  <CardBody>
                    <Row>
                      <Col xl={12}>
                        <h3>Coming Soon...</h3>
                        <h5>
                          Let us know which service you want supported by voting
                          below.
                        </h5>
                        <h5>
                          Votes remaining:{" "}
                          <span className="text-info">
                            {user.voted ? 0 : 1}
                          </span>
                        </h5>
                      </Col>
                      {/* <Col xl={3} sm={6} className="my-1">
                        <Card style={{ cursor: "pointer" }}>
                          <CardBody
                            className="text-center pt-0 pr-1"
                            onClick={() => handleVote("onedrive_business")}
                          >
                            <h6 className="text-right text-xs font-weight-normal text-muted mt-0">
                              votes:{" "}
                              <span className="text-info">
                                {votes.onedrive_business}
                              </span>
                            </h6>
                            <img
                              style={{ height: "20px" }}
                              alt="onedrive_business"
                              src={require("assets/img/services/onedrive.svg")}
                            />
                            <span className="text-primary text-xs">
                              {" "}
                              Business
                            </span>
                          </CardBody>
                        </Card>
                      </Col> */}
                      <Col xl={3} sm={6} className="my-1">
                        <Card style={{ cursor: "pointer" }}>
                          <CardBody
                            className="text-center pt-0 pr-1"
                            onClick={() => handleVote("box")}
                          >
                            <h6 className="text-right text-xs font-weight-normal text-muted mt-0">
                              votes:{" "}
                              <span className="text-info">{votes.box}</span>
                            </h6>
                            <img
                              style={{ height: "20px" }}
                              alt="box"
                              src={require("assets/img/services/box.svg")}
                            />
                          </CardBody>
                        </Card>
                      </Col>
                      <Col xl={3} sm={6} className="my-1">
                        <Card style={{ cursor: "pointer" }}>
                          <CardBody
                            className="text-center pt-0 pr-1"
                            onClick={() => handleVote("soundcloud")}
                          >
                            <h6 className="text-right text-xs font-weight-normal text-muted mt-0">
                              votes:{" "}
                              <span className="text-info">
                                {votes.soundcloud}
                              </span>
                            </h6>
                            <img
                              style={{ height: "25px" }}
                              alt="soundcloud"
                              src={require("assets/img/services/soundcloud.svg")}
                            />
                          </CardBody>
                        </Card>
                      </Col>
                      <Col xl={3} sm={6} className="my-1">
                        <Card style={{ cursor: "pointer" }}>
                          <CardBody
                            className="text-center pt-0 pr-1"
                            onClick={() => handleVote("icloud")}
                          >
                            <h6 className="text-right text-xs font-weight-normal text-muted mt-0">
                              votes:{" "}
                              <span className="text-info">{votes.icloud}</span>
                            </h6>
                            <img
                              style={{ height: "20px" }}
                              alt="icloud"
                              src={require("assets/img/services/icloud.svg")}
                            />
                            <span className="text-sm font-weight-bold">
                              {" "}
                              iCloud
                            </span>
                          </CardBody>
                        </Card>
                      </Col>
                      <Col xl={3} sm={6} className="my-1">
                        <Card style={{ cursor: "pointer" }}>
                          <CardBody
                            className="text-center pt-0 pr-1"
                            onClick={() => handleVote("backblaze")}
                          >
                            <h6 className="text-right text-xs font-weight-normal text-muted mt-0">
                              votes:{" "}
                              <span className="text-info">
                                {votes.backblaze}
                              </span>
                            </h6>
                            <img
                              style={{ height: "20px" }}
                              alt="backblaze"
                              src={require("assets/img/services/backblaze.svg")}
                            />
                          </CardBody>
                        </Card>
                      </Col>
                      <Col xl={3} sm={6} className="my-1">
                        <Card style={{ cursor: "pointer" }}>
                          <CardBody
                            className="text-center pt-0 pr-1"
                            onClick={() => handleVote("carbonite")}
                          >
                            <h6 className="text-right text-xs font-weight-normal text-muted mt-0">
                              votes:{" "}
                              <span className="text-info">
                                {votes.carbonite}
                              </span>
                            </h6>
                            <img
                              style={{ height: "20px" }}
                              alt="carbonite"
                              src={require("assets/img/services/carbonite.svg")}
                            />
                          </CardBody>
                        </Card>
                      </Col>
                      <Col xl={3} sm={6} className="my-1">
                        <Card style={{ cursor: "pointer" }}>
                          <CardBody
                            className="text-center pt-0 pr-1"
                            onClick={() => handleVote("aws_s3")}
                          >
                            <h6 className="text-right text-xs font-weight-normal text-muted mt-0">
                              votes:{" "}
                              <span className="text-info">{votes.aws_s3}</span>
                            </h6>
                            <img
                              style={{ height: "20px" }}
                              alt="aws_s3"
                              src={require("assets/img/services/awss3.svg")}
                            />
                            <span className="text-sm font-weight-bold">
                              {" "}
                              AWS S3
                            </span>
                          </CardBody>
                        </Card>
                      </Col>
                      <Col xl={3} sm={6} className="my-1">
                        <Card style={{ cursor: "pointer" }}>
                          <CardBody
                            className="text-center pt-0 pr-1"
                            onClick={() => handleVote("dailymotion")}
                          >
                            <h6 className="text-right text-xs font-weight-normal text-muted mt-0">
                              votes:{" "}
                              <span className="text-info">
                                {votes.dailymotion}
                              </span>
                            </h6>
                            <img
                              style={{ height: "20px" }}
                              alt="dailymotion"
                              src={require("assets/img/services/dailymotion.svg")}
                            />
                          </CardBody>
                        </Card>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </>
        )}
      </Container>
    </>
  );
};

export default BackupSetup;
