import React, { useEffect, useState } from "react";
// reactstrap components
import {
  Card,
  CardHeader,
  Table,
  Container,
  Row,
  Col,
  Spinner,
  Alert,
  Button
} from "reactstrap";
import WeVideoImage from './../assets/img/brand/WeVideo.png';

import {
  toggleAccount
} from "store/actions/accountActions";

import { useSelector, useDispatch } from "react-redux";

import { getLogs } from "store/actions/logActions";

import Header from "components/Headers/Header.js";
import LogItem from "components/LogItem/LogItem";

const BackupLog = () => {
  const [visible, setVisible] = useState(true);
  const onDismiss = () => setVisible(false);
  const dispatch = useDispatch();
  const { entries, loadingLogs } = useSelector((state) => state.log);
  const { subscription } = useSelector((state) => state.account);

  useEffect(() => {
    dispatch(getLogs());
    //eslint-disable-next-line
  }, []);

  useEffect(() => {
    if(subscription == 'Free' || subscription == 'Cancelled') {
      dispatch(toggleAccount());
    }
  }, [subscription])

  return (
    <>
      <Header />
      {/* Page content */}
      <Container className="mt--8" fluid>
        <Row className="my-5">
          <Col className="mb-8" xl="12">
            <Card className="shadow">
              <CardHeader className="border-0">
                <Row className="align-items-center">
                  <div className="col">
                    <h3 className="mb-0">Backup Events</h3>
                  </div>
                </Row>
              </CardHeader>

              <Alert color="secondary" className='px-4' isOpen={visible} toggle={onDismiss} closeClassName="text-secondary">
                <Row className="flex-nowrap">
                  <Col className="mr-2 d-none d-lg-flex col-auto">
                    <img src={WeVideoImage} width="150"></img>
                  </Col>
                  <Col  className="px-4">
                    <Row>
                      <h4 style={{ fontWeight: 'bold' }}>Edit your backed up Zoom recordings on WeVideo, or learn how.</h4>
                    </Row>
                    <Row>
                      <a href="https://zbackup.app/link/wevideo" style={{ display: 'inline-block', marginRight: '5px'}} target="_blank"><Button color="primary">Edit</Button></a>
                      <a href=" https://zbackup.app/link/wevideo-howto" style={{ display: 'inline-block' }} target="_blank"><Button color="secondary">Learn More</Button></a>
                    </Row>
                  </Col>
                </Row>
                 
              </Alert>

              <Table className="align-items-center table-flush" responsive>
                <thead className="thead-light">
                  <tr>
                    <th scope="col">Date</th>
                    <th scope="col">Topic</th>
                    <th scope="col">Length</th>
                    <th scope="col">Size</th>
                    <th scope="col">Backup Service</th>
                    <th scope="col" className="text-center">
                      Backup Status
                    </th>
                    <th scope="col" className="text-center">
                      Recordings
                    </th>
                  </tr>
                </thead>

                <tbody>
                  {loadingLogs ? (
                    <tr>
                      <th scope="row">
                        <Spinner className="mx-4 my-4" />
                      </th>
                    </tr>
                  ) : entries.length === 0 ? (
                    <tr>
                      <th scope="row">Nothing yet...check back soon!</th>
                    </tr>
                  ) : (
                    entries
                      // .sort((a, b) => {
                      //   if(a.start_time && b.start_time)
                      //     return new Date(b.start_time) - new Date(a.start_time);
                      //   else
                      //     return new Date(b.createdAt) - new Date(a.createdAt);
                      // })
                      .map((entry) => {
                        return <LogItem key={entry._id} data={entry} />;
                      })
                  )}
                </tbody>
              </Table>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default BackupLog;
