import React, { useMemo, useEffect, useState } from "react";
import moment from "moment";
// reactstrap components
import {
  Badge,
  Card,
  CardHeader,
  CardBody,
  Table,
  Container,
  Row,
  Col,
  Spinner,
  Button,
  Input,
  FormGroup,
  Label,
  Form,
} from "reactstrap";
import { useSelector, useDispatch } from "react-redux";
import {
  useTable,
  useSortBy,
  useGlobalFilter,
  useAsyncDebounce,
  usePagination,
} from "react-table";

import Header from "components/Headers/Header.js";

import {
  getAllUsers,
  loginAsUser,
  upgradeUser,
  downgradeUser,
} from "store/actions/superadminActions";

import {
  toggleAccount
} from "store/actions/accountActions";

const Superadmin = () => {
  const dispatch = useDispatch();
  const { users, loading } = useSelector((state) => state.super);
  const { subscription } = useSelector((state) => state.account);

  let data = [];

  useEffect(() => {
    dispatch(getAllUsers());
  }, [dispatch]);

  useEffect(() => {
    if(subscription == 'Free' || subscription == 'Cancelled') {
      dispatch(toggleAccount());
    }
  }, [subscription])

  const columns = useMemo(
    () => [
      {
        Header: "Actions",
        accessor: "login",
      },
      {
        Header: "Name",
        accessor: "name", // accessor is the "key" in the data
      },
      {
        Header: "Email",
        accessor: "email", // accessor is the "key" in the data
      },
      {
        Header: "Registered",
        accessor: "registered",
      },
      {
        Header: "Plan",
        accessor: "plan",
      },
      {
        Header: "Upgraded",
        accessor: "upgraded",
      },
      {
        Header: "Cancelled",
        accessor: "cancelled",
      },
      {
        Header: "Subscription URL",
        accessor: "subscriptionUrl",
      },
    ],
    []
  );

  const GlobalFilter = ({
    preGlobalFilteredRows,
    globalFilter,
    setGlobalFilter,
  }) => {
    const count = preGlobalFilteredRows.length;
    const [value, setValue] = useState(globalFilter);
    const onChange = useAsyncDebounce((value) => {
      setGlobalFilter(value || undefined);
    }, 500);
    return (
      <Form inline key="global-search-form">
        <FormGroup key="global-seach-group">
          <Label for="global-search" className="mr-2">
            Search:{" "}
          </Label>
          <Input
            autoFocus
            autoComplete="off"
            id="global-search"
            key="global-search-input"
            bsSize="sm"
            value={value || ""}
            onChange={(e) => {
              e.preventDefault();
              setValue(e.target.value);
              onChange(e.target.value);
            }}
            placeholder={`${count} records...`}
            className="px-2"
          />
        </FormGroup>
      </Form>
    );
  };

  const handleUpgrade = (id) => {
    upgradeUser(id);
  };

  const handleDowngrade = (id) => {
    downgradeUser(id);
  };

  data = useMemo(() => {
    const handleLogin = (id) => {
      dispatch(loginAsUser(id));
    };

    return users.map((user) => {
      return {
        login:
          user.role === "Superadmin" ? (
            "n/a (Superadmin)"
          ) : user.subscription === "Free" ||
            user.subscription === "Cancelled" ? (
            <>
              <Button
                color="primary"
                outline
                size="sm"
                onClick={() => handleLogin(user._id)}
              >
                Login
              </Button>
              <Button
                color="info"
                outline
                size="sm"
                onClick={() => {
                  if (
                    window.confirm(
                      `Are you sure you wish to grant prepaid/beta access for ${user.first_name} ${user.last_name}?`
                    )
                  )
                    handleUpgrade(user._id);
                }}
              >
                Give Prepaid
              </Button>
            </>
          ) : user.subscription === "Prepaid" ? (
            <>
              <Button
                color="primary"
                outline
                size="sm"
                onClick={() => handleLogin(user._id)}
              >
                Login
              </Button>
              <Button
                color="warning"
                outline
                size="sm"
                onClick={() => {
                  if (
                    window.confirm(
                      `Are you sure you wish to revoke prepaid/beta access for ${user.first_name} ${user.last_name}?`
                    )
                  )
                    handleDowngrade(user._id);
                }}
              >
                Revoke Prepaid
              </Button>
            </>
          ) : (
            <Button
              color="primary"
              outline
              size="sm"
              onClick={() => handleLogin(user._id)}
            >
              Login
            </Button>
          ),
        name: `${user.first_name} ${user.last_name}`,
        email: user.email,
        registered: moment(user.createdAt).format("DD MMM YYYY"),
        plan: (
          <Badge
            color={
              user.subscription === "Free"
                ? "primary"
                : user.subscription === "Trial"
                ? "info"
                : user.subscription === "Starter"
                ? "success"
                : user.subscription === "Non-Renewing"
                ? "warning"
                : user.subscription === "Cancelled"
                ? "danger"
                : user.subscription === "Prepaid"
                ? "secondary"
                : "secondary"
            }
          >
            {user.subscription}
          </Badge>
        ),
        upgraded: user.zoho_start_date
          ? moment(user.zoho_start_date).format("DD MMM YYYY")
          : "n/a",
        cancelled: user.zoho_end_date
          ? moment(user.zoho_end_date).format("DD MMM YYYY")
          : "n/a",
        subscriptionUrl: user.zoho_subscription_id ? (
          <a
            href={`https://subscriptions.zoho.com/app#/subscriptions/${user.zoho_subscription_id}`}
            rel="noopener noreferrer"
            target="_blank"
            className="text-underline text-muted text-xs"
          >{`https://subscriptions.zoho.com/app#/subscriptions/${user.zoho_subscription_id}`}</a>
        ) : (
          "n/a"
        ),
      };
    });
  }, [users, dispatch]);

  let tableInstance = useTable(
    {
      columns,
      data,
    },
    useGlobalFilter,
    useSortBy,
    usePagination
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    canPreviousPage,
    canNextPage,
    nextPage,
    previousPage,
    setPageSize,
    state: { pageSize },
    prepareRow,
    state,
    preGlobalFilteredRows,
    setGlobalFilter,
  } = tableInstance;

  return (
    <>
      <Header />
      {/* Page content */}
      <Container className="mt--8" fluid>
        <Row className="mt-5">
          <Col className="mb-8" xl="12">
            <Card className="shadow">
              <CardHeader className="border-0">
                <Row className="align-items-center">
                  <div className="col">
                    <h3 className="mb-0">User List</h3>
                  </div>
                </Row>
              </CardHeader>
              <CardBody>
                <GlobalFilter
                  preGlobalFilteredRows={preGlobalFilteredRows}
                  globalFilter={state.globalFilter}
                  setGlobalFilter={setGlobalFilter}
                />
              </CardBody>
              {loading ? (
                <div className="text-center mb-8">
                  <Spinner color="primary" />
                </div>
              ) : (
                <>
                  <Table
                    className="align-items-center table-flush"
                    responsive
                    {...getTableProps()}
                  >
                    <thead className="thead-light">
                      {headerGroups.map((headerGroup) => (
                        <tr {...headerGroup.getHeaderGroupProps()}>
                          {headerGroup.headers.map((column) => (
                            <th
                              scope="col"
                              {...column.getHeaderProps(
                                column.getSortByToggleProps()
                              )}
                            >
                              {
                                // Render the header
                                column.render("Header")
                              }
                              <span>
                                {column.isSorted
                                  ? column.isSortedDesc
                                    ? " ▲"
                                    : " ▼"
                                  : ""}
                              </span>
                            </th>
                          ))}
                        </tr>
                      ))}
                    </thead>
                    {/* Apply the table body props */}
                    <tbody {...getTableBodyProps()}>
                      {page.map((row, i) => {
                        prepareRow(row);
                        return (
                          <tr {...row.getRowProps()}>
                            {row.cells.map((cell) => {
                              return (
                                <td
                                  {...cell.getCellProps()}
                                  style={{
                                    textOverflow: "ellipsis",
                                  }}
                                >
                                  {cell.render("Cell")}
                                </td>
                              );
                            })}
                          </tr>
                        );
                      })}
                    </tbody>
                  </Table>
                </>
              )}
              <>
                <hr className="mb-3 mt-0" />

                <div className="d-flex justify-content-center mb-3">
                  <Input
                    type="select"
                    bsSize="sm"
                    style={{ width: "120px" }}
                    className="mr-3"
                    value={pageSize}
                    onChange={(e) => {
                      setPageSize(Number(e.target.value));
                    }}
                  >
                    {[10, 20, 30, 40, 50].map((pageSize) => (
                      <option key={pageSize} value={pageSize}>
                        {pageSize} per page
                      </option>
                    ))}
                  </Input>
                  <Button
                    color="primary"
                    outline
                    size="sm"
                    onClick={() => previousPage()}
                    disabled={!canPreviousPage}
                  >
                    {"<"}
                  </Button>{" "}
                  <Button
                    color="primary"
                    outline
                    size="sm"
                    onClick={() => nextPage()}
                    disabled={!canNextPage}
                  >
                    {">"}
                  </Button>
                </div>
              </>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default Superadmin;
