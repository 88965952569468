import React, { useEffect } from "react";
import { useLocation, useHistory } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
// reactstrap components
import { Container, Row, Col, Button, Spinner } from "reactstrap";
import queryString from "query-string";
import oauth from "helpers/oauth";

// core components
import AuthNavbar from "components/Navbars/AuthNavbar.js";
import AuthFooter from "components/Footers/AuthFooter.js";

// actions
import { zoomAuth } from "store/actions/authActions";

const Auth = () => {
  const location = useLocation();
  const history = useHistory();
  const dispatch = useDispatch();
  const { isAuthenticated, loading } = useSelector((state) => state.auth);

  useEffect(() => {
    if (isAuthenticated) {
      if (location.state && location.state.redirect) {
        history.push(
          location.state.redirect.pathname + location.state.redirect.search
        );
      } else {
        history.push("/admin");
      }
    }
    document.body.classList.add("bg-default");
    return () => {
      document.body.classList.remove("bg-default");
    };
    //eslint-disable-next-line
  }, [history, isAuthenticated]);

  useEffect(() => {
    const parsed = queryString.parse(location.search);
    if (parsed.code) {
      dispatch(zoomAuth(parsed.code));
    }
  }, [location, dispatch]);

  return (
    <>
      <div className="main-content">
        <AuthNavbar />
        <div className="header bg-gradient-info py-7 py-lg-9">
          <Container>
            <div className="header-body text-center">
              <Row className="justify-content-center">
                <Col lg="8" md="12">
                  {loading ? (
                    <>
                      <span className="text-lead text-light font-weight-bold">
                        Talking to Zoom
                      </span>
                      <br />
                      <Spinner color="white" type="grow" className="my-3" />
                      <br />
                      <span className="text-lead text-light font-weight-bold">
                        Logging you in
                      </span>
                      <br />
                      <p className="text-xxs text-light font-weight-500 mt-2">
                        Just doing computer things...
                      </p>
                      <br />
                    </>
                  ) : (
                    <>
                      <h1
                        className="text-white my-0"
                        style={{
                          fontSize: "20px",
                          textShadow: "6px 5px 15px 4px rgba(0,0,0,0.24)",
                        }}
                      >
                        Connect your Zoom account to get started!
                      </h1>

                      <Button
                        onClick={() => window.open(oauth.zoom, "_self")}
                        color="secondary"
                        size="lg"
                        style={{
                          fontSize: "15px",
                          boxShadow: "6px 5px 15px 4px rgba(0,0,0,0.24)",
                          border: "1px light-gray solid",
                        }}
                        className="text-dark mt-3 mb-3 py-3 px-6 text-uppercase font-weight-900"
                      >
                        Login W/{" "}
                        <img
                          style={{ height: "23px" }}
                          alt="..."
                          src={require("assets/img/services/zoom.svg")}
                          className="mt--1 ml-2"
                        />
                      </Button>
                      <h4 className="text-white mt-1">
                        (You will be redirected to Zoom to securely login)
                      </h4>
                      <h5 className="text-white mt-3">
                        Psst...looking to switch accounts?{" "}
                        <a
                          href="https://zoom.us/logout"
                          className="text-white text-underline"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          Log out of Zoom
                        </a>
                      </h5>
                    </>
                  )}
                </Col>
              </Row>
            </div>
          </Container>
          <div className="separator separator-bottom separator-skew zindex-100">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              preserveAspectRatio="none"
              version="1.1"
              viewBox="0 0 2560 100"
              x="0"
              y="0"
            >
              <polygon
                className="fill-default"
                points="2560 0 2560 100 0 100"
              />
            </svg>
          </div>
        </div>
      </div>
      <Container fluid className="fixed-bottom">
        <AuthFooter />
      </Container>
    </>
  );
};

export default Auth;
