// Sets the JSON web token on all server requests, if it exists
import axios from "axios";

const setAuthToken = () => {
  axios.defaults.withCredentials = true;
  // if (token) {
  //   // Apply authorization token to every request if logged in
  //   axios.defaults.headers.common["Authorization"] = token;
  // } else {
  //   // Delete auth header
  //   delete axios.defaults.headers.common["Authorization"];
  // }
};

export default setAuthToken;
