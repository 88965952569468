import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import {
  Button,
  Col,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
  Popover,
  PopoverHeader,
  PopoverBody,
  Spinner,
  Input,
} from "reactstrap";

import {
  getHostedPage,
  getSubscriptionInfo,
  getPaymentUpdatePage,
  cancelSubscription,
  reactivateSubscription,
} from "store/actions/accountActions";

const AccountModal = ({ isOpen, toggle, name, service, settings }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const {
    modalOpen,
    subscription,
    trial_ends,
    subscribeUrl,
    paymentUrl,
    loading,
    end_date,
  } = useSelector((state) => state.account);
  const [cancelConfirm, setCancelConfirm] = useState(false);
  const [updatePayment, setUpdatePayment] = useState(false);
  const [promo, setPromo] = useState("");

  const { user } = useSelector((state) => state.auth);
  const { first_name, last_name, email, zoom_id } = user;

  useEffect(() => {
    dispatch(getSubscriptionInfo());
    if (subscription === "Free" || subscription === "Cancelled") {
      dispatch(getHostedPage({ promo: "" }));
    }
  }, [subscription, dispatch]);

  const toggleCancel = () => setCancelConfirm(!cancelConfirm);

  const handleUpdatePaymentMethod = () => {
    dispatch(getPaymentUpdatePage());
    setUpdatePayment(true);
  };

  const handleCancelSubscription = () => {
    setCancelConfirm(false);
    dispatch(cancelSubscription());
    history.push("/admin/settings?cancel=success");
  };

  const handleReactivate = () => {
    dispatch(reactivateSubscription());
    history.push("/admin/settings?subscribe=success");
  };

  const handlePromo = () => {
    dispatch(getHostedPage({ promo }));
  };

  return (
    <Modal
      isOpen={modalOpen}
      toggle={toggle}
      size="lg"
      centered
      style={{ background: "transparent" }}
    >
      <ModalHeader toggle={toggle}>Account & Billing</ModalHeader>
      {loading ? (
        <div className="text-center mb-5" style={{ minHeight: "930px" }}>
          <Spinner size="md" color="info" />
        </div>
      ) : (
        <ModalBody>
          {subscription === "Free" || subscription === "Cancelled" ? (
            <>
              <Row>
                <Col md={8} className="offset-md-2 text-center">
                  <h3>Unlock more functionality...</h3>
                  <p>
                    Subscribe to zBackup premium to get access to all backup
                    providers and setup backups for up to three service
                    accounts.
                  </p>
                </Col>
              </Row>
              <Row>
                <Col md={4} className={"offset-md-4 text-center"}>
                  <h4>Have a promo code?</h4>
                  <div className="d-flex">
                    <Input
                      type="text"
                      style={{ borderRadius: "5px 0px 0px 5px" }}
                      value={promo}
                      onChange={(e) => setPromo(e.target.value)}
                    />
                    <Button
                      onClick={handlePromo}
                      color="primary"
                      size="sm"
                      className="px-4"
                      style={{ borderRadius: "0px 5px 5px 0px" }}
                    >
                      Apply
                    </Button>
                  </div>
                </Col>
              </Row>
              <Row>
                <Col md={12}>
                  <iframe
                    title="subscribe"
                    id="subscribe-iframe"
                    width="100%"
                    height={window.innerWidth < 990 ? "1250" : "920"}
                    src={subscribeUrl}
                    style={{ border: "none" }}
                  ></iframe>
                </Col>
              </Row>
            </>
          ) : (
            <div className="text-left">
              <Row>
                <Col md={8} className="offset-md-2 text-center">
                  <h3>Manage your zBackup account...</h3>
                </Col>
              </Row>
              <hr />
              <h4>
                <span className="text-muted">Name: </span>
                {first_name} {last_name}
              </h4>
              <h4>
                <span className="text-muted">Email: </span>
                {email}
              </h4>
              <h4>
                <span className="text-muted">Zoom ID: </span>
                {zoom_id}
              </h4>
              <h4>
                <span className="text-muted">Current Plan: </span>
                {subscription}
              </h4>
              {subscription === "Trial" && (
                <h4>
                  <span className="text-muted">Trial Ends: </span>
                  {trial_ends}
                </h4>
              )}
              {subscription !== "Non-Renewing" && subscription !== "Prepaid" ? (
                <>
                  <Button
                    onClick={handleUpdatePaymentMethod}
                    className="mt-3 mb-2"
                    color="info"
                    size="sm"
                  >
                    Update Payment Method
                  </Button>
                  {updatePayment && (
                    <iframe
                      title="update-iframe"
                      id="update-iframe"
                      width="100%"
                      height="665"
                      src={paymentUrl}
                      style={{ border: "none" }}
                    ></iframe>
                  )}
                  <br />
                  <Button
                    id="cancel-popover"
                    className="mt-2"
                    color="warning"
                    size="sm"
                  >
                    Cancel Account
                  </Button>
                  <Popover
                    placement="right"
                    isOpen={cancelConfirm}
                    target="cancel-popover"
                    toggle={toggleCancel}
                    trigger="legacy"
                  >
                    <PopoverHeader>Are you sure?</PopoverHeader>
                    <PopoverBody>
                      You will lose access to paid features at the end of the
                      current billing period. Your account will still allow 1
                      free backup location (Dropbox or Vimeo). All others will
                      be set inactive. No previously backed up recordings will
                      be removed or changed.
                      <div className="my-4 text-center">
                        <Button
                          onClick={handleCancelSubscription}
                          color="danger"
                          size="sm"
                        >
                          Yes, Cancel my Account
                        </Button>
                      </div>
                    </PopoverBody>
                  </Popover>
                </>
              ) : (
                subscription !== "Prepaid" && (
                  <>
                    <h4>
                      <span className="text-muted">Subscription Ends: </span>
                      {end_date}
                    </h4>
                    <Button
                      onClick={handleReactivate}
                      className="mt-3 mb-2"
                      color="info"
                      outline
                      size="sm"
                    >
                      Reactivate Subscription
                    </Button>
                  </>
                )
              )}
              <hr />
            </div>
          )}
        </ModalBody>
      )}
    </Modal>
  );
};

export default AccountModal;
