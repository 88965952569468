import { SET_PREV_REC_ERROR } from "store/actions/types";
import { SET_PREV_REC_SERVICES_ERROR } from "store/actions/types";
import { SET_PREV_RECORDINGS } from "store/actions/types";
import { SET_PREV_REC_LOADING } from "store/actions/types";


const initialState = {
  recordings: [],
  loading: false,
  prevRecError: false,
  prevRecServicesError: false
}

export default function (state = initialState, action) {
  switch (action.type) {
    case SET_PREV_REC_LOADING:
      return {
        ...state,
        loading: action.payload
      }
    case SET_PREV_RECORDINGS:
      return {
        ...state,
        recordings: action.payload,
        prevRecError: false
      }
    case SET_PREV_REC_ERROR:
      return {
        ...state,
        prevRecError: action.payload
      }
    case SET_PREV_REC_SERVICES_ERROR:
      return {
        ...state,
        prevRecServicesError: action.payload
      }
    default:
      return state;
  }
}