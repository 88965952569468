import { SET_ALL_USERS, USERS_LOADING, SET_CURRENT_USER } from "./types";
import axios from "axios";

// Set current user in the Redux Store
export const setCurrentUser = (decoded) => {
  return {
    type: SET_CURRENT_USER,
    payload: decoded,
  };
};

// Get all services associate with a user's account
export const getAllUsers = () => (dispatch) => {
  dispatch({
    type: USERS_LOADING,
    payload: true,
  });
  axios
    .get("/api/superadmin/list")
    .then((res) => {
      dispatch({
        type: SET_ALL_USERS,
        payload: res.data,
      });
    })
    .catch((err) => {
      dispatch({
        type: USERS_LOADING,
        payload: false,
      });
    });
};

// Log in as a specified user
export const loginAsUser = (id) => (dispatch) => {
  axios.post("/api/superadmin/login-as-user", { targetId: id }).then((res) => {
    dispatch(finalizeLogin(res.data));
  });
};

// Upgrade specified user to Prepaid account
export const upgradeUser = (id) => {
  axios.post("/api/superadmin/upgrade-user", { targetId: id }).then((res) => {
    window.location.reload(false);
  });
};

// Upgrade specified user to Prepaid account
export const downgradeUser = (id) => {
  axios.post("/api/superadmin/downgrade-user", { targetId: id }).then((res) => {
    window.location.reload(false);
  });
};
export const finalizeLogin = (token) => (dispatch) => {
  window.location.reload(false);
};
