import { SET_LOGS_LOADING, GET_LOGS } from "store/actions/types";

const initialState = {
  loadingLogs: false,
  entries: [],
};

export default function (state = initialState, action) {
  switch (action.type) {
    case SET_LOGS_LOADING:
      return {
        ...state,
        loadingLogs: action.payload,
      };
    case GET_LOGS:
      return {
        ...state,
        entries: action.payload,
        loadingLogs: false,
      };
    default:
      return state;
  }
}
