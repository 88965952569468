import React from "react";
import ReactDatetime from 'react-datetime';

import {
  FormGroup,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
} from 'reactstrap';

const Datepicker = (props) => {
  return (
    <>
      <FormGroup>
        <InputGroup className="input-group-alternative">
          <InputGroupAddon addonType="prepend">
            <InputGroupText>
              <i className="ni ni-calendar-grid-58" />
            </InputGroupText>
          </InputGroupAddon>
          <ReactDatetime {...props} timeFormat={false} />
        </InputGroup>
      </FormGroup>
    </>
  )
}

export default Datepicker;
