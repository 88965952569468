import React from "react";
// reactstrap components
import { NavbarBrand, Navbar, Container } from "reactstrap";

class AdminNavbar extends React.Component {
  render() {
    return (
      <>
        <Navbar
          className="navbar-top navbar-horizontal navbar-dark"
          expand="md"
        >
          <Container className="px-4">
            <NavbarBrand href="https://zbackup.app">
              <img alt="..." src={require("assets/img/brand/logo-white.svg")} />
            </NavbarBrand>
          </Container>
        </Navbar>
      </>
    );
  }
}

export default AdminNavbar;
