import jwt_decode from "jwt-decode";
import axios from "axios";
import setAuthToken from "helpers/setAuthToken";
import {
  SET_CURRENT_USER,
  USER_LOADING,
  SET_USER_SUBSCRIPTION,
  SET_DELETE_AFTER_UPLOAD,
} from "./types";

// Set current user in the Redux Store
export const setCurrentUser = (decoded) => {
  return {
    type: SET_CURRENT_USER,
    payload: decoded,
  };
};

// Send off Zoom auth code and create user
export const zoomAuth = (code) => (dispatch) => {
  dispatch({
    type: USER_LOADING,
    payload: true,
  });
  axios
    .post("/api/auth/zoom", { code })
    .then((res) => {
      dispatch(finalizeLogin(res.data));
    })
    .catch((err) => {
      dispatch({
        type: USER_LOADING,
        payload: false,
      });
    });
};

// Log out current user by removing auth token
export const checkAuth = () => (dispatch) => {
  axios
    .get("/api/auth/refresh")
    .then((res) => {
      dispatch(setCurrentUser(res.data));
    })
    .catch((err) => {
      dispatch({
        type: USER_LOADING,
        payload: false,
      });
    });
};

// Log out current user by removing auth token
export const logoutUser = () => (dispatch) => {
  axios.get("/api/auth/logout")
  setAuthToken(false);
  dispatch(setCurrentUser({}));
};

export const finalizeLogin = (token) => (dispatch) => {
  // localStorage.setItem("jwtToken", token);
  // setAuthToken(token);
  const decoded = jwt_decode(token);
  const { subscription, delete_after_upload } = decoded;
  dispatch(setCurrentUser(decoded));
  dispatch({
    type: SET_DELETE_AFTER_UPLOAD,
    payload: delete_after_upload,
  });
  dispatch({
    type: SET_USER_SUBSCRIPTION,
    payload: subscription,
  });
};
