import {
  SET_SUBSCRIBE_PAGE,
  TOGGLE_ACCOUNT_MODAL,
  ACCOUNT_LOADING,
  SET_USER_SUBSCRIPTION,
  SET_SUBSCRIPTION_INFO,
  SET_PAYMENT_UPDATE,
  SET_CANCEL_DATE,
} from "store/actions/types";

const initialState = {
  modalOpen: false,
  subscription: "Trial",
  trial_ends: "",
  loading: false,
  subscribeUrl: "",
  paymentUrl: "",
  end_date: "",
};

export default function (state = initialState, action) {
  switch (action.type) {
    case TOGGLE_ACCOUNT_MODAL:
      return {
        ...state,
        modalOpen: !state.modalOpen,
      };
    case ACCOUNT_LOADING:
      return {
        ...state,
        loading: action.payload,
      };
    case SET_SUBSCRIBE_PAGE:
      return {
        ...state,
        subscribeUrl: action.payload,
        loading: false,
      };
    case SET_PAYMENT_UPDATE:
      return {
        ...state,
        paymentUrl: action.payload,
      };
    case SET_CANCEL_DATE:
      return {
        ...state,
        end_date: action.payload,
        subscription: "Non-Renewing",
      };
    case SET_USER_SUBSCRIPTION:
      return {
        ...state,
        subscription: action.payload,
      };
    case SET_SUBSCRIPTION_INFO:
      return {
        ...state,
        trial_ends: action.trial_ends,
        subscription: action.subscription,
        end_date: action.end_date,
        loading: false,
      };
    default:
      return state;
  }
}
