import React from "react";
import { Button, Modal, ModalBody, ModalHeader } from "reactstrap";

const ReviewAskModal = ({ isOpen, toggle }) => {
  return (
    <Modal size="md" centered={true} isOpen={isOpen} toggle={toggle}>
      <ModalHeader className="h2 mb-0 mx-auto text-center mt-3">
        <h1>That was easy!</h1>
      </ModalHeader>
      <ModalBody className="pt-0">
        All your new Zoom recordings will now automatically sync to your
        destination. Feel free to come back any time you need to update your
        settings.
        <br />
        <br />
        Before you go, <b>can you take a minute to leave us a review?</b>
        <br />
        <div className="text-center mt-4 mb-3 w-100">
          <Button color="outline-success py2" style={{ width: "200px" }}>
            <a
              href="https://trustpilot.com/evaluate/zbackup.app"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img
                style={{ height: "35px" }}
                className=""
                alt="TrustPilot Logo"
                src={require("assets/img/reviews/trustpilot.svg")}
              />
            </a>
          </Button>
          <Button color="outline-success py-2" style={{ width: "200px" }}>
            <a
              href="https://www.g2.com/products/zbackup-app/reviews/start"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img
                style={{ height: "38px" }}
                className=""
                alt="G2 Reviews Logo"
                src={require("assets/img/reviews/g2.png")}
              />
            </a>
          </Button>
        </div>
        <div className="text-center mx-auto">
          <img
            style={{ width: "250px" }}
            alt="..."
            src={require("assets/img/gifs/rocket.gif")}
          />
        </div>
        <h5
          className="text-underline text-muted text-center mt-4 cursor-pointer"
          onClick={toggle}
        >
          Close
        </h5>
      </ModalBody>
    </Modal>
  );
};

export default ReviewAskModal;
