import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Route, Switch, Redirect } from "react-router-dom";
// reactstrap components
import { Container } from "reactstrap";
// core components
import AdminNavbar from "components/Navbars/AdminNavbar.js";
import AdminFooter from "components/Footers/AdminFooter.js";
import Sidebar from "components/Sidebar/Sidebar.js";

import routes from "routes.js";
import AccountModal from "components/Modals/AccountModal";
import { toggleAccount } from "store/actions/accountActions";

const Admin = (props) => {
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.auth);

  useEffect(() => {
    const script = document.createElement("script");
    script.src =
      "https://desk.zoho.com/portal/api/web/inapp/543424000000189001?orgId=727075757";
    script.defer = true;
    script.type = "text/javascript";
    document.body.appendChild(script);
    window.ZohoHCAsapSettings = {
      ticketsSettings: {
        preFillFields: {
          email: {
            defaultValue: user.email,
          },
          contactId: {
            defaultValue: user.first_name + " " + user.last_name,
          },
        },
      },
    };
    return () => {
      document.body.removeChild(script);
    };
    //eslint-disable-next-line
  }, []);

  const getRoutes = (routes) => {
    return routes.map((prop, key) => {
      if (prop.layout === "/admin") {
        return (
          <Route
            path={prop.layout + prop.path}
            component={prop.component}
            key={key}
          />
        );
      } else {
        return null;
      }
    });
  };

  const getBrandText = (path) => {
    for (let i = 0; i < routes.length; i++) {
      if (
        props.location.pathname.indexOf(routes[i].layout + routes[i].path) !==
        -1
      ) {
        return routes[i].name;
      }
    }
    return "Brand";
  };

  const handleToggleAccount = (e) => {
    e.preventDefault();
    dispatch(toggleAccount());
  };

  return (
    <>
      <Sidebar
        {...props}
        routes={routes}
        logo={{
          innerLink: "/admin/index",
          imgSrc: require("assets/img/brand/logo.svg"),
          imgAlt: "...",
        }}
      />
      <div className="main-content">
        <AdminNavbar
          {...props}
          brandText={getBrandText(props.location.pathname)}
        />
        <Switch>
          {getRoutes(routes)}
          <Redirect from="*" to="/admin/settings" />
        </Switch>
        <Container fluid className="footer fixed-bottom mt-4">
          <AdminFooter />
        </Container>
        <AccountModal toggle={handleToggleAccount} />
      </div>
    </>
  );
};

export default Admin;
