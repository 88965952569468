import React, { useEffect, useMemo } from 'react';

import { useSelector, useDispatch } from 'react-redux';
import moment from 'moment';

import { triggerMeetingBackup } from 'store/actions/prevRecordingActions';


import {
  useTable,
  useSortBy,
  usePagination,
} from 'react-table';
import { Spinner, Table, Input, Button, Alert } from 'reactstrap';
import { setPrevRecServicesError } from 'store/actions/prevRecordingActions';
import { useHistory } from 'react-router';

const PreviousRecordingsTable = (props) => {

  const dispatch = useDispatch();

  const history = useHistory();

  const { recordings, loading, prevRecError, prevRecServicesError } = useSelector(state => state.prevRecordings);

  const toggleServiceError = () => {
    dispatch(setPrevRecServicesError(!prevRecServicesError));
  }

  const goToSettings = () => {
    dispatch(setPrevRecServicesError(false));
    history.push('/admin/settings');
  }

  const columns = useMemo(() => [
    {
      Header: 'Date',
      accessor: 'start_time',
    },
    {
      Header: 'Topic',
      accessor: 'topic'
    },
    {
      Header: 'Meeting ID',
      accessor: 'id',
    },
    {
      Header: 'Backup Meeting',
      accessor: 'back_up_status',
    },
  ], []);

  const data = useMemo(() => {
    const backupMeeting = (uuid) => {
      dispatch(triggerMeetingBackup(uuid));
    }

    console.log(recordings)

    const printBackupStatus = (pr) => {
      if(pr.recording_count == 0 || pr.recording_files.some((recording) => recording.status == 'processing')) {
          return <span className="text-info">No Files or Processing</span>
      } else {
        if(pr.back_up_status === 2) {
          return  <Button size="sm" color="secondary" onClick={() => backupMeeting(pr.uuid)}>Retry Backup</Button>
        } else if (pr.back_up_status === 1) {
          return <span className="text-info">Backing up.</span>
        } else if (pr.back_up_status === -1) {
          return <Spinner size="sm" color="info" />
        } else {
          return <Button size="sm" color="info" onClick={() => backupMeeting(pr.uuid)}>Back Up</Button>
        }
      }
    }

    return recordings.map(pr => {
      return {
        ...pr,
        start_time: moment(pr.start_time).format('L [at] LT'),
        back_up_status: printBackupStatus(pr)
      }
    });

  }, [recordings, dispatch]);


  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,

    page,
    nextPage,
    previousPage,
    setPageSize,
    canNextPage,
    canPreviousPage,
    state: { pageSize },

    prepareRow,
  } = useTable(
    {
      columns,
      data,
    },
    useSortBy,
    usePagination,
  );

  return (
    loading ? (
      <div className="text-center my-4">
        <Spinner color="primary" />
      </div>
    ) : prevRecError ? (
      <div className="text-center my-4">
        <h3 className="text-info">An error occurred. Please try again.</h3>
      </div>
    ) : recordings.length > 0 && (
      <>

        <Alert
          color="warning"
          isOpen={prevRecServicesError}
          className="fixed-top mt-lg-4 mt-6 ml-lg-9 mr-lg-4 mx-2 font-weight-bold"
          toggle={toggleServiceError}
        >
          You do not have any active services to backup to. Please activate a service in the <span style={{cursor: 'pointer'}} onClick={goToSettings}><u>settings page</u></span>, and then try again.
        </Alert>

        <Table
          className="align-items-center table-flush mt-3"
          responsive
          {...getTableProps()}
        >

          <thead className="thead-light">
            {headerGroups.map((headerGroup) => (
              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column) => (
                  <th scope="col" {...column.getHeaderProps(column.getSortByToggleProps())}>
                    {column.render("Header")}
                    <span>{column.isSorted ? column.isSortedDesc ? " ▲" : " ▼" : ""}</span>
                  </th>
                ))}
              </tr>
            ))}
          </thead>

          <tbody {...getTableBodyProps()}>
            {page.map((row) => {
              prepareRow(row);
              return (
                <tr {...row.getRowProps()}>
                  {row.cells.map((cell) => (
                    <td {...cell.getCellProps()} style={{ textOverflow: "ellipsis" }}>{cell.render("Cell")}</td>
                  ))}
                </tr>
              )
            })}
          </tbody>

        </Table>

        <hr className="mb-3 mt-0" />

        <div className="d-flex justify-content-center mb-3">
          <Input
            type="select"
            bsSize="sm"
            style={{ width: "120px" }}
            className="mr-3"
            value={pageSize}
            onChange={(e) => {
              setPageSize(Number(e.target.value));
            }}
          >
            {[10, 20, 30, 40, 50].map((pageSize) => (
              <option key={pageSize} value={pageSize}>
                {pageSize} per page
              </option>
            ))}
          </Input>
          <Button
            color="primary"
            outline
            size="sm"
            onClick={() => previousPage()}
            disabled={!canPreviousPage}
          >
            {"<"}
          </Button>{" "}
          <Button
            color="primary"
            outline
            size="sm"
            onClick={() => nextPage()}
            disabled={!canNextPage}
          >
            {">"}
          </Button>
        </div>
      </>
    )
  )
}

export default PreviousRecordingsTable;