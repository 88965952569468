import axios from "axios";
import { GET_LOGS, SET_LOGS_LOADING } from "./types";

// Return all logs for the user
export const getLogs = () => (dispatch) => {
  dispatch({
    type: SET_LOGS_LOADING,
    payload: true,
  });
  axios
    .get("/api/log/list")
    .then((res) => {
      dispatch({
        type: GET_LOGS,
        payload: res.data,
      });
    })
    .catch((err) => {
      dispatch({
        type: SET_LOGS_LOADING,
        payload: false,
      });
    });
};
