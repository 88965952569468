

import axios from 'axios';
import {
  SET_PREV_REC_LOADING,
  SET_PREV_RECORDINGS,
  SET_PREV_REC_ERROR,
  SET_PREV_REC_SERVICES_ERROR,
} from "./types";

import moment from 'moment';


export const searchPreviousRecordings = (from, to) => (dispatch) => {
  dispatch({
    type: SET_PREV_REC_LOADING,
    payload: true
  });

  if (moment(to).format('YYYY-MM-DD') === moment().format('YYYY-MM-DD')) {
    to = moment().add(1, 'd').format('YYYY-MM-DD');
  }

  axios.get("/api/previous-recordings/search", { params: { from, to }})
    .then((res) => {
      dispatch({
        type: SET_PREV_RECORDINGS,
        payload: res.data.recordings
      });
    })
    .catch((error) => {
      console.log(error);
      dispatch({
        type: SET_PREV_REC_ERROR,
        payload: true
      });
    })
    .finally(() => {
      dispatch({
        type: SET_PREV_REC_LOADING,
        payload: false
      });
    });
}

export const triggerMeetingBackupAll = (uuids) => (dispatch, getState) => {
  const setMeetingBackupStatus = (status) => {
    const { prevRecordings: { recordings } } = getState();
    const newRecordings = Array.from(recordings);

    // Loop through all the meetings to be backed up
    for(let i = 0; i < uuids.length; i++) {

      // Check if the recording id matches the meeting id to be backed up
      const recIndex = newRecordings.findIndex(pr => pr.uuid == uuids[i]);

      // If it does, then change status to backing up
      if (recIndex > -1) {
        newRecordings[recIndex].back_up_status = status;
      }
    }

    dispatch({
      type: SET_PREV_RECORDINGS,
      payload: newRecordings
    });
  }
  
  setMeetingBackupStatus(-1);

  axios.post("/api/previous-recordings/schedule-backup-all", { uuids })
    .then((res) => {
      const { status } = res.data;
      if (status === 'scheduled') {
        return setMeetingBackupStatus(1);
      }

      if (status === 'no-active-backups') {
        setMeetingBackupStatus(0);
        dispatch(setPrevRecServicesError(true));
        return;
      }
    })
    .catch((error) => {
      console.log(error);
      setMeetingBackupStatus(0);
    });
}

export const triggerMeetingBackup = (uuid) => (dispatch, getState) => {
  const setMeetingBackupStatus = (status) => {
    const { prevRecordings: { recordings } } = getState();
    const newRecordings = Array.from(recordings);
    const recIndex = newRecordings.findIndex(pr => pr.uuid === uuid);
    if (recIndex > -1) {
      newRecordings[recIndex].back_up_status = status;
    }
    dispatch({
      type: SET_PREV_RECORDINGS,
      payload: newRecordings
    });
  }

  setMeetingBackupStatus(-1);

  axios.post("/api/previous-recordings/schedule-meeting-back-up", { uuid })
    .then((res) => {
      const { status } = res.data;
      if (status === 'scheduled') {
        return setMeetingBackupStatus(1);
      }

      if (status === 'no-active-backups') {
        setMeetingBackupStatus(0);
        dispatch(setPrevRecServicesError(true));
        return;
      }
    })
    .catch((error) => {
      console.log(error);
      setMeetingBackupStatus(0);
    });

}

export const setPrevRecServicesError = (status) => {
  return {
    type: SET_PREV_REC_SERVICES_ERROR,
    payload: status,
  }
}
