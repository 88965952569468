import axios from "axios";
import {
  TOGGLE_ACCOUNT_MODAL,
  SET_SUBSCRIBE_PAGE,
  ACCOUNT_LOADING,
  SET_USER_SUBSCRIPTION,
  SET_SUBSCRIPTION_INFO,
  SET_PAYMENT_UPDATE,
  SET_CANCEL_DATE,
  SET_DELETE_AFTER_UPLOAD,
} from "./types";

// Get the information on the account
export const toggleAccount = () => {
  return {
    type: TOGGLE_ACCOUNT_MODAL,
  };
};

// Get a hosted page on for a new subscription
export const getHostedPage = ({ promo }) => (dispatch) => {
  dispatch({
    type: ACCOUNT_LOADING,
    payload: true,
  });
  axios
    .post("/api/subscription/create-hosted-page", { promo })
    .then((res) => {
      dispatch({
        type: SET_SUBSCRIBE_PAGE,
        payload: res.data,
      });
    })
    .catch(() => {
      dispatch({
        type: ACCOUNT_LOADING,
        payload: false,
      });
    });
};

// Set the delete after upload global account setting
export const setDeleteAfterUpload = ({ value }) => (dispatch) => {
  axios.post("/api/subscription/delete-after-upload", { value }).then((res) => {
    dispatch({
      type: SET_DELETE_AFTER_UPLOAD,
      payload: value,
    });
  });
};

export const getDeleteAfterUpload = () => (dispatch) => {
  axios.get("/api/subscription/get-delete-after-upload").then((res) => {
    dispatch({
      type: SET_DELETE_AFTER_UPLOAD,
      delete: res.data.deleteAfterUpload,
      voted: res.data.voted,
    });
  });
};

// Get a hosted page for updating payment
export const getPaymentUpdatePage = () => (dispatch) => {
  axios.get("/api/subscription/update-payment").then((res) => {
    dispatch({
      type: SET_PAYMENT_UPDATE,
      payload: res.data,
    });
  });
};

export const setSubscriberInfo = (hostedPageId) => (dispatch) => {
  axios
    .post("/api/subscription/set-subscriber", { hostedPageId })
    .then((res) => {
      if (res.data === "Trial" || res.data === "Starter")
        dispatch({
          type: SET_USER_SUBSCRIPTION,
          payload: res.data,
        });
    });
};

// Get the subscription info on the account
export const getSubscriptionInfo = () => (dispatch) => {
  dispatch({
    type: ACCOUNT_LOADING,
    payload: true,
  });
  axios
    .get("/api/subscription/list")
    .then((res) => {
      dispatch({
        type: SET_SUBSCRIPTION_INFO,
        trial_ends: res.data.trial_ends,
        subscription: res.data.subscription,
        end_date: res.data.end_date,
      });
    })
    .catch(() => {
      dispatch({
        type: SET_SUBSCRIPTION_INFO,
        trial_ends: "Unknown",
        subscription: "Error",
      });
    });
};

// Cancel zoho subscription
export const cancelSubscription = () => (dispatch) => {
  axios.get("/api/subscription/cancel").then((res) => {
    const { cancel } = res.data;
    dispatch({
      type: SET_CANCEL_DATE,
      payload: cancel,
    });
  });
};

// Cancel zoho subscription
export const reactivateSubscription = () => (dispatch) => {
  axios.get("/api/subscription/reactivate").then((res) => {
    if (res.data === "Trial" || res.data === "Starter")
      dispatch({
        type: SET_SUBSCRIPTION_INFO,
        subscription: res.data,
        end_date: "",
      });
  });
};

// Get a Zoho subscriptions payment gateway for the user
export const getPaymentGateway = () => {};
