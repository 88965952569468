export const SET_CURRENT_USER = "SET_CURRENT_USER";
export const USER_LOADING = "USER_LOADING";
export const ADD_SERVICE = "ADD_SERVICE";
export const SET_SERVICES = "SET_SERVICES";
export const TOGGLE_ACTIVE = "TOGGLE_ACTIVE";
export const REMOVE_SERVICE = "REMOVE_SERVICE";
export const UPDATE_OPTIONS = "UPDATE_OPTIONS";
export const UPDATE_BACKUP_OPTIONS = "UPDATE_BACKUP_OPTIONS";
export const SERVICE_LOADING = "SERVICE_LOADING";
export const SET_ALL_USERS = "SET_ALL_USERS";
export const USERS_LOADING = "USERS_LOADING";
export const SET_SUBSCRIBE_PAGE = "SET_SUBSCRIBE_PAGE";
export const TOGGLE_ACCOUNT_MODAL = "TOGGLE_ACCOUNT_MODAL";
export const ACCOUNT_LOADING = "ACCOUNT_LOADING";
export const SET_USER_SUBSCRIPTION = "SET_USER_SUBSCRIPTION";
export const SET_SUBSCRIPTION_INFO = "SET_SUBSCRIPTION_INFO";
export const SET_PAYMENT_UPDATE = "SET_PAYMENT_UPDATE";
export const SET_CANCEL_DATE = "SET_CANCEL_DATE";
export const SET_DELETE_AFTER_UPLOAD = "SET_DELETE_AFTER_UPLOAD";
export const SET_VOTES = "SET_VOTES";
export const GET_LOGS = "GET_LOGS";
export const SET_LOGS_LOADING = "SET_LOGS_LOADING";

export const SET_PREV_REC_LOADING = "SET_PREV_REC_LOADING";
export const SET_PREV_RECORDINGS = "SET_PREV_RECORDINGS";
export const SET_PREV_REC_ERROR = "SET_PREV_REC_ERROR";
export const SET_PREV_REC_SERVICES_ERROR = "SET_PREV_REC_SERVICES_ERROR";
