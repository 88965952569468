/* eslint-disable no-nested-ternary */
import React from "react";
import { Route, Redirect } from "react-router-dom";
import { useSelector } from "react-redux";

import Admin from "layouts/Admin";

const SuperadminRoute = ({ component: Component, ...rest }) => {
  const { user } = useSelector((state) => state.auth);

  return (
    <Route
      {...rest}
      render={(props) =>
        user.role === "Superadmin" ? (
          <Admin {...props} />
        ) : (
          <Redirect to={{ pathname: "/admin" }} {...props} />
        )
      }
    />
  );
};

export default SuperadminRoute;
