import React, { useState } from "react";
import moment from "moment";
import { Button, Popover, PopoverBody, PopoverHeader } from "reactstrap";
import SetupModal from "components/Modals/SetupModal";

const BackupItem = ({
  active,
  service,
  created,
  location,
  id,
  options,
  name,
  settings,
  removeService,
  toggleEdit,
  backupOptions,
  toggleService,
}) => {
  const [popoverOpen, setPopoverOpen] = useState(false);
  const [serviceSetup, setServiceSetup] = useState(false);

  const handleDelete = () => {
    removeService(id);
  };

  const toggle = () => {
    setPopoverOpen(!popoverOpen);
  };

  const handleActive = () => {
    toggleService(id, active, service);
  };

  const toggleOptions = () => {
    setServiceSetup(!serviceSetup);
  };

  return (
    <>
      <tr>
        <td className="text-muted">
          <label className="toggle-control">
            <input type="checkbox" checked={active} onChange={handleActive} />
            <span className="control"></span>
          </label>
        </td>
        <th scope="row">{service}</th>
        <td className="text-muted">{name}</td>
        <td className="text-xs">
          <span>
            {options.fail_email ? (
              <span className="text-success">✓</span>
            ) : (
              <span className="text-danger">✗</span>
            )}{" "}
            Email upon failure
          </span>
          <br />
          <span>
            {options.success_email ? (
              <span className="text-success">✓</span>
            ) : (
              <span className="text-danger">✗</span>
            )}{" "}
            Email upon success
          </span>
        </td>
        <td>{moment(created).format("MMM DD YYYY [at] h:mm:ss a")}</td>
        <td>
          <a
            href={location}
            target="_blank"
            rel="noopener noreferrer"
            className="text-underline text-xs"
          >
            {location}
          </a>
        </td>
        <td className="text-right">
          <Button
            size="sm"
            color="secondary"
            outline
            className="mx-0 px-3"
            style={{ borderRadius: 0 }}
            onClick={toggleOptions}
          >
            Options
          </Button>
          <Button
            size="sm"
            color="danger"
            outline
            id={`DeletePopover${id}`}
            className="mx-0"
            style={{ borderRadius: 0 }}
          >
            Delete
          </Button>
          <Popover
            placement="left"
            isOpen={popoverOpen}
            target={`DeletePopover${id}`}
            toggle={toggle}
            trigger="legacy"
          >
            <PopoverHeader>Confirm Delete</PopoverHeader>
            <PopoverBody className="text-left mb-2 mt-1">
              <p className="text-sm">
                Are you sure you want to delete this <strong>{service}</strong>{" "}
                backup?
              </p>
              <p className="text-sm">Existing files will not be deleted.</p>
              <div className="text-right">
                <Button size="sm" color="secondary" onClick={toggle}>
                  Cancel
                </Button>
                <Button size="sm" color="danger" onClick={handleDelete}>
                  Delete
                </Button>
              </div>
            </PopoverBody>
          </Popover>
        </td>
      </tr>
      <SetupModal
        isOpen={serviceSetup}
        toggle={toggleOptions}
        name={name}
        service={service}
        id={id}
        options={options}
        backupOptions={backupOptions}
      />
    </>
  );
};

export default BackupItem;
