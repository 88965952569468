import React from "react";
import moment from "moment";

const LogItem = ({ data }) => {
  return (
    <tr>
      <td>{moment(data.mergedDate).local().format("MM/DD/YY [at] h:mmA")}</td>
      <td>{data.topic}</td>
      <td>{data.length} Minutes</td>
      <td>{Math.round(data.size / 1000000)} mb</td>
      <td className="text-capitalize">{data.service}</td>
      <td className="text-center">
        {data.status === "success" ? (
          <span className="badge badge-success">Success</span>
        ) : data.status === "over-limit" ? (
          <span className="badge badge-warning">Limit Exceeded</span>
        ) : (
          <span className="badge badge-danger">Failed</span>
        )}
      </td>
      <td className="text-center">
        {data.recordings.map((rec) => {
          if (rec.upload === "n/a") {
            return <></>;
          } else {
            return (
              rec.status !== "skipped" && (
                <div key={rec._id}>
                  {rec.url && rec.url.length > 0 ? (
                    <a
                      className="font-size-xs text-underline"
                      href={rec.url ? rec.url : ""}
                      rel="noopener noreferrer"
                      target="_blank"
                    >
                      {rec.file_name}
                    </a>
                  ) : rec.status === "failed" ? (
                    <span style={{ color: "#f5365c" }}>{rec.file_name}</span>
                  ) : (
                    <span>{rec.file_name}</span>
                  )}
                  <br />
                </div>
              )
            );
          }
        })}
      </td>
    </tr>
  );
};

export default LogItem;
