import React from "react";
// reactstrap components
import { Container } from "reactstrap";

class Header extends React.Component {
  render() {
    return (
      <>
        <div className="header bg-gradient-info pb-8 pt-0 pt-md-3">
          <Container fluid></Container>
        </div>
      </>
    );
  }
}

export default Header;
