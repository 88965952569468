import React, { useState } from "react";
import { useDispatch } from "react-redux";
import {
  Form,
  FormGroup,
  Label,
  Input,
  Modal,
  ModalBody,
  ModalHeader,
  Button,
  Row,
  Col,
  UncontrolledTooltip,
} from "reactstrap";

import {
  setNotificationOptions,
  setBackupOptions,
} from "store/actions/serviceActions";

const SetupModal = ({
  isOpen,
  toggle,
  name,
  options,
  id,
  service,
  backupOptions,
}) => {
  const dispatch = useDispatch();
  const [form, setForm] = useState(backupOptions);

  const updateOptions = (e) => {
    dispatch(
      setNotificationOptions({
        id,
        target: e.target.id,
        value: e.target.checked,
      })
    );
  };

  const handleChange = (e) => {
    const { id, value } = e.target;
    setForm({ ...form, [id]: value });
  };

  const handleCheck = (e) => {
    const { id, checked } = e.target;
    setForm({ ...form, [id]: checked });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    dispatch(setBackupOptions({ id, form }));
    toggle();
  };

  return (
    <Modal isOpen={isOpen} size="md" centered>
      <ModalHeader className="text-uppercase mb-0" toggle={toggle}>
        Options - {name}
      </ModalHeader>
      <ModalBody className="pb-4 pt-2">
        <h5>Backup Options</h5>
        <div className="text-sm">
          <Form inline className="text-wrap" onSubmit={handleSubmit}>
            <FormGroup className="w-100 mb-2">
              <Label htmlFor="minBackup">
                1. Only backup meetings longer than
              </Label>
              <Input
                style={{ maxWidth: "65px", fontSize: "12px" }}
                bsSize="sm"
                type="select"
                name="minBackup"
                id="minBackup"
                min={0 || form.maxBackup}
                className="mx-2"
                value={form.minBackup}
                onChange={handleChange}
              >
                <option value={0}>0</option>
                <option
                  value={5}
                  disabled={form.maxBackup !== 0 && form.maxBackup <= 5}
                >
                  5
                </option>
                <option
                  value={15}
                  disabled={form.maxBackup !== 0 && form.maxBackup <= 15}
                >
                  15
                </option>
                <option
                  value={30}
                  disabled={form.maxBackup !== 0 && form.maxBackup <= 30}
                >
                  30
                </option>
                <option
                  value={60}
                  disabled={form.maxBackup !== 0 && form.maxBackup <= 60}
                >
                  60
                </option>
                <option
                  value={90}
                  disabled={form.maxBackup !== 0 && form.maxBackup <= 90}
                >
                  90
                </option>
                <option
                  value={120}
                  disabled={form.maxBackup !== 0 && form.maxBackup <= 120}
                >
                  120
                </option>
                <option
                  value={240}
                  disabled={form.maxBackup !== 0 && form.maxBackup <= 240}
                >
                  240
                </option>
              </Input>{" "}
              <Label htmlFor="maxBackup">minutes and shorter than</Label>{" "}
              <Input
                bsSize="sm"
                style={{ fontSize: "12px", maxWidth: "100px" }}
                type="select"
                name="maxBackup"
                id="maxBackup"
                className="mx-2"
                value={form.maxBackup}
                onChange={handleChange}
              >
                <option value={5} disabled={form.minBackup >= 5}>
                  5
                </option>
                <option value={15} disabled={form.minBackup >= 15}>
                  15
                </option>
                <option value={30} disabled={form.minBackup >= 30}>
                  30
                </option>
                <option value={60} disabled={form.minBackup >= 60}>
                  60
                </option>
                <option value={90} disabled={form.minBackup >= 90}>
                  90
                </option>
                <option value={120} disabled={form.minBackup >= 120}>
                  120
                </option>
                <option value={240} disabled={form.minBackup >= 240}>
                  240
                </option>
                <option value={0}>unlimited</option>
              </Input>
              minutes.
            </FormGroup>
            <br />
            <FormGroup className="w-100 my-2">
              <Row>
                <Col>
                  <span className="mr-2">2. Backup File Types:</span>
                </Col>
              </Row>
              <br />
              <Row className="text-left">
                <Col sm={4} className="ml-5 ml-sm-0">
                  <Label check>
                    <Input
                      type="checkbox"
                      id="video"
                      defaultChecked={form.video}
                      onChange={handleCheck}
                    />{" "}
                    Video
                  </Label>
                </Col>
                <Col sm={4} className="ml-5 ml-sm-0">
                  <Label check>
                    <Input
                      type="checkbox"
                      id="audio"
                      disabled={service === "Youtube" || service === "Vimeo"}
                      defaultChecked={form.audio}
                      onChange={handleCheck}
                    />{" "}
                    Audio
                  </Label>
                </Col>
                <Col sm={4} className="ml-5 ml-sm-0">
                  <Label check>
                    <Input
                      type="checkbox"
                      id="chat"
                      disabled={service === "Youtube" || service === "Vimeo"}
                      defaultChecked={form.chat}
                      onChange={handleCheck}
                    />{" "}
                    Chat Text
                  </Label>
                </Col>
                <Col sm={4} className="ml-5 ml-sm-0">
                  <Label check>
                    <Input
                      type="checkbox"
                      id="timeline"
                      disabled={service === "Youtube" || service === "Vimeo"}
                      defaultChecked={form.timeline}
                      onChange={handleCheck}
                    />{" "}
                    Timeline
                  </Label>
                </Col>
              </Row>
            </FormGroup>
            <br />
            <FormGroup className="w-100 my-2">
              <Row>
                <Col>
                  <span className="mr-2">3. Backup Meeting Types:</span>
                </Col>
              </Row>
              <br />
              <Row>
                <Col sm={4} className="ml-5 ml-sm-0">
                  <Label check className="mx-1">
                    <Input
                      type="checkbox"
                      id="instant"
                      defaultChecked={form.instant}
                      onChange={handleCheck}
                    />{" "}
                    Instant
                  </Label>
                </Col>
                <Col sm={4} className="ml-5 ml-sm-0">
                  <Label check className="mx-1">
                    <Input
                      type="checkbox"
                      id="scheduled"
                      defaultChecked={form.scheduled}
                      onChange={handleCheck}
                    />{" "}
                    Scheduled/Recurring
                  </Label>
                </Col>
                <Col sm={4} className="ml-5 ml-sm-0">
                  <Label check className="mx-1">
                    <Input
                      type="checkbox"
                      id="personalId"
                      defaultChecked={form.personalId}
                      onChange={handleCheck}
                    />{" "}
                    Personal ID
                  </Label>
                </Col>
                <Col sm={4} className="ml-5 ml-sm-0">
                  <Label check className="mx-1">
                    <Input
                      type="checkbox"
                      id="dialIn"
                      defaultChecked={form.dialIn}
                      onChange={handleCheck}
                    />{" "}
                    Dial-in/PAC
                  </Label>
                </Col>
                <Col sm={4} className="ml-5 ml-sm-0">
                  <Label check className="mx-1">
                    <Input
                      type="checkbox"
                      id="webinar"
                      defaultChecked={form.webinar}
                      onChange={handleCheck}
                    />{" "}
                    Webinar
                  </Label>
                </Col>
              </Row>
            </FormGroup>
            <br />
            <FormGroup className="w-100 my-2">
              <Label htmlFor="backupKeyword">
                4. Backup only if meeting topic contains keyword{" "}
                <i className="fas fa-info-circle mx-1" id="keywordTooltip" />
                <UncontrolledTooltip
                  placement="left"
                  target="keywordTooltip"
                  className="shadow"
                  style={{ fontWeight: 300, fontSize: "12.5px" }}
                >
                  Keyword is not case sentive
                </UncontrolledTooltip>{" "}
              </Label>
              <Input
                style={{ width: "110px", fontSize: "12px" }}
                bsSize="sm"
                type="text"
                name="backupKeyword"
                id="backupKeyword"
                className="mx-2"
                defaultValue={form.backupKeyword}
                onChange={handleChange}
              />
            </FormGroup>
            <br />
            <FormGroup className="w-100 my-2">
              <Label htmlFor="skipKeyword">
                5. Skip backup if meeting topic contains keyword{" "}
                <i className="fas fa-info-circle mx-1" id="keywordTooltip2" />
                <UncontrolledTooltip
                  placement="left"
                  target="keywordTooltip2"
                  className="shadow"
                  style={{ fontWeight: 300, fontSize: "12.5px" }}
                >
                  Keyword is not case sentive
                </UncontrolledTooltip>{" "}
              </Label>
              <Input
                style={{ width: "110px", fontSize: "12px" }}
                bsSize="sm"
                type="text"
                name="skipKeyword"
                id="skipKeyword"
                className="mx-2"
                defaultValue={form.skipKeyword}
                onChange={handleChange}
              />
            </FormGroup>

            {
              name == "Youtube" && (
                <>
                  <FormGroup className="w-100 my-2">
                    <Label htmlFor="youtubeTitle">
                      6. Default Title{" "}
                      <i className="fas fa-info-circle mx-1" id="youtubeTitle" />
                      <UncontrolledTooltip
                        placement="left"
                        target="youtubeTitle"
                        className="shadow"
                        style={{ fontWeight: 300, fontSize: "12.5px" }}
                      >
                        Your meeting date and title will still appear after your default title.
                      </UncontrolledTooltip>{" "}
                    </Label>
                    <Input
                      style={{ width: "100%", fontSize: "12px" }}
                      bsSize="sm"
                      type="text"
                      name="youtubeTitle"
                      id="youtubeTitle"
                      className="mx-2"
                      defaultValue={form.youtubeTitle}
                      onChange={handleChange}
                    />
                  </FormGroup>

                  <FormGroup className="w-100 my-2">
                    <Label htmlFor="youtubeDesc">
                      7. Default Description{" "}
                    </Label>
                    <Input
                      style={{ width: "100%", fontSize: "12px" }}
                      bsSize="sm"
                      type="textarea"
                      name="youtubeDesc"
                      id="youtubeDesc"
                      className="mx-2"
                      defaultValue={form.youtubeDesc}
                      onChange={handleChange}
                    />
                  </FormGroup>

                  <FormGroup className="w-100 my-2 space-x-2">
                    <Label for="youtubePrivacy">
                    8. Default Privacy{" "}
                    <i className="fas fa-info-circle mx-1" id="youtubeTitle" />
                      <UncontrolledTooltip
                        placement="left"
                        target="youtubeTitle"
                        className="shadow"
                        style={{ fontWeight: 300, fontSize: "12.5px" }}
                      >
                        Warning: You are highly encouraged to keep this set to "Private" to prevent inadvertent uploads to the public. Once the video is uploaded to YouTube, you can confirm you want it visible to the public.
                      </UncontrolledTooltip>{" "}
                    </Label>
                    <Input
                      bsSize="sm"
                      style={{ fontSize: "12px", maxWidth: "100px", width: "100%", marginLeft: '6px' }}
                      id="youtubePrivacy"
                      name="youtubePrivacy"
                      type="select"
                      value={form.youtubePrivacy}
                      onChange={handleChange}
                    >
                      <option value={'public'}>
                        Public
                      </option>
                      <option value={'private'}>
                        Private
                      </option>
                      <option value={'unlisted'}>
                        Unlisted
                      </option>
                    </Input>
                  </FormGroup>
                </>
              )
            }


            <div className="mx-auto d-flex mt-4">
              <Button type="submit" color="primary" className="shadow">
                Save Backup Options
              </Button>
            </div>
          </Form>
        </div>

        <hr />
        <h5>Notification Options</h5>
        <Form>
          <div className="custom-control custom-checkbox mb-3">
            <input
              className="custom-control-input"
              id="fail_email"
              type="checkbox"
              defaultChecked={options.fail_email}
              onChange={updateOptions}
            />
            <label className="custom-control-label" htmlFor="fail_email">
              Receive email notification on failed backup
            </label>
          </div>
          <div className="custom-control custom-checkbox mb-3">
            <input
              className="custom-control-input"
              id="success_email"
              type="checkbox"
              defaultChecked={options.success_email}
              onChange={updateOptions}
            />
            <label className="custom-control-label" htmlFor="success_email">
              Receive email notification on successful backup
            </label>
          </div>
        </Form>
      </ModalBody>
    </Modal>
  );
};

export default SetupModal;
