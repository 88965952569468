import {
  SET_SERVICES,
  ADD_SERVICE,
  TOGGLE_ACTIVE,
  REMOVE_SERVICE,
  UPDATE_OPTIONS,
  UPDATE_BACKUP_OPTIONS,
  SERVICE_LOADING,
  SET_VOTES,
} from "store/actions/types";

const initialState = {
  services: [],
  votes: [],
  loading: false,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case SET_SERVICES:
      return {
        ...state,
        services: action.payload,
        loading: false,
      };
    case ADD_SERVICE:
      return {
        ...state,
        services: [...state.services, action.payload],
        loading: false,
      };
    case TOGGLE_ACTIVE:
      return {
        ...state,
        services: state.services.map((svc) =>
          svc._id === action.payload ? { ...svc, active: !svc.active } : svc
        ),
        loading: false,
      };
    case UPDATE_OPTIONS:
      return {
        ...state,
        services: state.services.map((svc) =>
          svc._id === action.id
            ? {
                ...svc,
                options: { ...svc.options, [action.target]: action.value },
              }
            : svc
        ),
      };
    case UPDATE_BACKUP_OPTIONS:
      return {
        ...state,
        services: state.services.map((svc) =>
          svc._id === action.id
            ? {
                ...svc,
                backupOptions: action.form,
              }
            : svc
        ),
      };
    case REMOVE_SERVICE:
      return {
        ...state,
        services: state.services.filter((svc) => svc._id !== action.payload),
        loading: false,
      };
    case SERVICE_LOADING:
      return {
        ...state,
        loading: action.payload,
      };
    case SET_VOTES:
      return {
        ...state,
        votes: action.payload,
      };
    default:
      return state;
  }
}
