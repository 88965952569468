import {
  SET_SERVICES,
  ADD_SERVICE,
  REMOVE_SERVICE,
  UPDATE_OPTIONS,
  TOGGLE_ACTIVE,
  SERVICE_LOADING,
  UPDATE_BACKUP_OPTIONS,
  SET_VOTES,
  SET_DELETE_AFTER_UPLOAD,
} from "./types";
import axios from "axios";

// Get all services associate with a user's account
export const getServices = () => (dispatch) => {
  dispatch({
    type: SERVICE_LOADING,
    payload: true,
  });
  axios
    .get("/api/service/list")
    .then((res) => {
      dispatch({
        type: SET_SERVICES,
        payload: res.data,
      });
      return res.data.length;
    })
    .catch((err) => {
      dispatch({
        type: SERVICE_LOADING,
        payload: false,
      });
    });
};

// Add a service to a user's account
export const addService = ({ service, code }) => (dispatch) => {
  dispatch({
    type: SERVICE_LOADING,
    payload: true,
  });
  axios
    .post(`/api/service/${service}`, { code })
    .then((res) => {
      dispatch({
        type: ADD_SERVICE,
        payload: res.data,
      });
    })
    .catch((err) => {
      dispatch({
        type: SERVICE_LOADING,
        payload: false,
      });
    });
};

// Get all services associate with a user's account
export const toggleActive = ({ id }) => (dispatch) => {
  dispatch({
    type: SERVICE_LOADING,
    payload: true,
  });
  axios
    .post("/api/service/toggle", { id })
    .then((res) => {
      dispatch({
        type: TOGGLE_ACTIVE,
        payload: id,
      });
    })
    .catch((err) => {
      dispatch({
        type: SERVICE_LOADING,
        payload: false,
      });
    });
};

// setNotificationOptions associated with a specific backup
export const setNotificationOptions = ({ id, target, value }) => (dispatch) => {
  axios
    .post("/api/service/set-options", { id, target, value })
    .then((res) => {
      dispatch({
        type: UPDATE_OPTIONS,
        id,
        target,
        value,
      });
    })
    .catch((err) => {
      dispatch({
        type: SERVICE_LOADING,
        payload: false,
      });
    });
};

// setNotificationOptions associated with a specific backup
export const setBackupOptions = ({ id, form }) => (dispatch) => {
  axios
    .post("/api/service/set-backup-options", { id, form })
    .then((res) => {
      dispatch({
        type: UPDATE_BACKUP_OPTIONS,
        id,
        form,
      });
    })
    .catch((err) => {
      dispatch({
        type: SERVICE_LOADING,
        payload: false,
      });
    });
};

// Remove a service from a user's account
export const removeService = ({ id }) => (dispatch) => {
  axios
    .post(`/api/service/remove`, { id })
    .then((res) => {
      dispatch({
        type: REMOVE_SERVICE,
        payload: id,
      });
    })
    .catch((err) => {
      dispatch({
        type: SERVICE_LOADING,
        payload: false,
      });
    });
};

// Get the votes for future services
export const getVotes = () => (dispatch) => {
  axios.get("/api/service/get-votes").then((res) => {
    dispatch({
      type: SET_VOTES,
      payload: res.data,
    });
  });
};

// Set the user's vote for a service
export const setVote = ({ service }) => (dispatch) => {
  axios.post("/api/service/set-vote", { service }).then((res) => {
    dispatch({
      type: SET_VOTES,
      payload: res.data,
    });
    dispatch({
      type: SET_DELETE_AFTER_UPLOAD,
      voted: true,
    });
  });
};
