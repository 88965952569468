import Datepicker from "components/Datepicker/Datepicker";
import Header from "components/Headers/Header";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Card, CardBody, CardHeader, Col, Container, Row, Button, Alert } from "reactstrap";
import moment from "moment";

import { useDispatch, useSelector } from "react-redux";

import { searchPreviousRecordings } from "store/actions/prevRecordingActions";
import PreviousRecordingsTable from "components/PreviousRecordingsTable/PreviousRecordingsTable";
import { triggerMeetingBackupAll } from "store/actions/prevRecordingActions";
import WeVideoImage from './../assets/img/brand/WeVideo.png';

const dayFormat = 'YYYY-MM-DD';

const validateMomentObject = (obj) => {
  return obj && typeof obj.isValid === 'function' && obj.isValid();
};

const PreviousRecordings = (props) => {
  const [visible, setVisible] = useState(true);
  const onDismiss = () => setVisible(false);

  const dispatch = useDispatch();

  const _today = useMemo(() => moment(), []);
  const _7DaysAgo = useMemo(() => _today.subtract(7, 'd'), [_today]);

  const [fromDate, setFromDate] = useState(moment().subtract(7, 'd'));
  const [toDate, setToDate] = useState(moment());
  const { recordings, loading } = useSelector(state => state.prevRecordings);

  const renderDay = useCallback((dateProps, currentDate) => {

    let classes = dateProps.className;

    if (validateMomentObject(fromDate) && validateMomentObject(toDate)) {
      if (fromDate.format(dayFormat) === currentDate.format(dayFormat)) {
        classes += ' start-date';
      } else if (fromDate.isBefore(currentDate)) {
        if (toDate.isAfter(currentDate)) {
          if (toDate.format(dayFormat) === currentDate.format(dayFormat)) {
            classes += ' end-date';
          } else {
            classes += ' middle-date';
          }
        } else if (toDate.format(dayFormat) === currentDate.format(dayFormat)) {
          classes += ' end-date';
        }
      }

    }

    return (
      <td {...dateProps} className={classes}>
        {currentDate.date()}
      </td>
    )

  }, [fromDate, toDate]);

  const isValidFromDate = useCallback((currentDate) => {
    if (validateMomentObject(toDate)) {
      if (currentDate.isBefore(toDate) && toDate.diff(currentDate, 'd') < 30) {
        return true;
      }
    }
    return false;
  }, [toDate]);

  const isValidToDate = useCallback((currentDate) => {
    if (validateMomentObject(fromDate)) {
      if (currentDate.isAfter(fromDate) && currentDate.diff(fromDate, 'd') < 30) {
        return true;
      }
    }
    return false;
  }, [fromDate]);

  const search = () => {
    if (validateMomentObject(fromDate) && validateMomentObject(toDate)) {
      dispatch(searchPreviousRecordings(fromDate.format(dayFormat), toDate.format(dayFormat)));
    }
  }

  const backupAllMeetings = () => {
    dispatch(triggerMeetingBackupAll([...recordings.filter((recording) => recording.back_up_status == 0).map((recording) => recording.uuid)]))
  }

  return (
    <>
      <Header />
      <Container className="mt--8" fluid>
        <Row className="my-5">
          <Col className="mb-8" xl="12">
            <Card className="shadow" style={{overflow: 'visible'}}>
              <CardHeader className="border-0">
                <Row className="align-items-center">
                  <div className="col">
                    <h3 className="mb-0">Previous Recordings</h3>
                  </div>
                </Row>
              </CardHeader>


              <Alert color="secondary" className='px-4' isOpen={visible} toggle={onDismiss} closeClassName="text-secondary">
                <Row className="flex-nowrap">
                  <Col className="mr-2 d-none d-lg-flex col-auto">
                    <img src={WeVideoImage} width="150"></img>
                  </Col>
                  <Col  className="px-4">
                    <Row>
                      <h4 style={{ fontWeight: 'bold' }}>Edit your backed up Zoom recordings on WeVideo, or learn how.</h4>
                    </Row>
                    <Row>
                      <a href="https://zbackup.app/link/wevideo" style={{ display: 'inline-block', marginRight: '5px'}} target="_blank"><Button color="primary">Edit</Button></a>
                      <a href=" https://zbackup.app/link/wevideo-howto" style={{ display: 'inline-block' }} target="_blank"><Button color="secondary">Learn More</Button></a>
                    </Row>
                  </Col>
                </Row>
                 
              </Alert>

              <CardBody className="pb-0 pt-0">
                <Row>
                  <Col sm={6}>
                    <div>From:</div>
                    <Datepicker
                      renderDay={renderDay}
                      className="start-date"
                      value={fromDate}
                      onChange={(e) => {
                        setFromDate(e);
                        const diff = toDate.diff(e, 'd');
                        const to = moment(e);
                        if (diff > 29) {
                          to.add(29, 'days');
                          setToDate(to);
                        } else if (diff < 1) {
                          to.add(1, 'days');
                          setToDate(to);
                        }
                      }}
                    />
                  </Col>
                  <Col sm={6}>
                    <div>To: <span className="text-muted">(max of 1 month)</span></div>
                    <Datepicker
                      renderDay={renderDay}
                      className="end-date"
                      value={toDate}
                      onChange={(e) => setToDate(e)}
                      isValidDate={isValidToDate}
                    />
                  </Col>
                </Row>
                <Row className="justify-content-center mb-2">
                  <Button
                    onClick={search}
                    color="primary"
                    size="md"
                    className="btn"
                  >
                    Search Recordings
                  </Button>

                  {!loading && recordings.filter((recording) => recording.back_up_status == 0).length > 0 && <Button
                    onClick={backupAllMeetings}
                    color="info"
                    size="md"
                    className="btn"
                  >
                    Backup All
                  </Button>}
                </Row>
              </CardBody>

              <PreviousRecordingsTable />

            </Card>
          </Col>
        </Row>
      </Container>
    </>
  )
}

export default PreviousRecordings;
