import {
  SET_CURRENT_USER,
  USER_LOADING,
  SET_DELETE_AFTER_UPLOAD,
} from "store/actions/types";
const isEmpty = require("is-empty");

const initialState = {
  isAuthenticated: false,
  user: {},
  errors: {},
  loading: false,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case SET_CURRENT_USER:
      return {
        ...state,
        isAuthenticated: !isEmpty(action.payload),
        user: action.payload,
        loading: false,
      };
    case USER_LOADING:
      return {
        ...state,
        loading: action.payload,
      };
    case SET_DELETE_AFTER_UPLOAD:
      return {
        ...state,
        user: {
          ...state.user,
          delete_after_upload: action.delete,
          voted: action.voted,
        },
      };
    default:
      return state;
  }
}
